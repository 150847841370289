/* eslint-disable no-use-before-define */
import React from 'react'

const Attendee = ({ state, stateKey, attendeeNumber, handleChange }: any) => {
  return (
    <>
      <br />
      <div className='row mb-1'>
        <div className='col-md-12'>
          <b>Attendee {attendeeNumber}</b>
        </div>
        <div className='col-md-4 mb-3'>
          <label htmlFor='firstName'>
            First Name <sup> *</sup>
          </label>
          <input
            className='form-control'
            type='text'
            placeholder='First Name'
            onChange={e => {
              handleChange(stateKey, 'firstName', e.target.value)
            }}
            value={state.firstName}
          />
          <div className='invalid-feedback'>Valid first name is required.</div>
        </div>
        <div className='col-md-4 mb-3'>
          <label htmlFor='lastName'>
            Last Name <sup> *</sup>{' '}
          </label>
          <input
            className='form-control'
            type='text'
            placeholder='Last Name'
            onChange={e => {
              handleChange(stateKey, 'lastName', e.target.value)
            }}
            value={state.lastName}
          />
          <div className='invalid-feedback'>Valid last name is required.</div>
        </div>
        <div className='col-md-4 mb-3'>
          <label htmlFor='dob'>
            Date of Birth <sup> *</sup>
          </label>
          <input
            className='form-control'
            type='date'
            placeholder='mm/dd/yyyy'
            onChange={e => {
              handleChange(stateKey, 'dob', e.target.value)
            }}
            value={state.dob}
          />
          <div className='invalid-feedback'>Valid Date of Birth required.</div>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-4 mb-3'>
          <label htmlFor='schoolName'>
            School Name <sup> *</sup>
          </label>
          <input
            className='form-control'
            type='text'
            placeholder='School Name'
            onChange={e => {
              handleChange(stateKey, 'schoolName', e.target.value)
            }}
            value={state.schoolName}
          />
          <div className='invalid-feedback'>
            Please provide valid School Name.{' '}
          </div>
        </div>
        <div className='col-md-4 mb-3'>
          <label htmlFor='homeroomTeacher'>Homeroom Teacher</label>
          <input
            className='form-control'
            type='text'
            placeholder='Homeroom Teacher'
            onChange={e => {
              handleChange(stateKey, 'homeroomTeacher', e.target.value)
            }}
            value={state.homeroomTeacher}
          />
          <div className='invalid-feedback'>
            Please provide valid Homeroom Teacher Name.{' '}
          </div>
        </div>
        <div className='col-md-4 mb-3'>
          <label htmlFor='grade'>Grade</label>
          <select
            className='form-select'
            onChange={e => {
              handleChange(stateKey, 'grade', e.target.value)
            }}
            value={state.grade}
          >
            <option>SELECT ONE</option>
            <option value='0'>Pre-K</option>
            <option value='1'>K</option>
            <option value='2'>1st</option>
            <option value='3'>2nd</option>
            <option value='4'>3rd</option>
            <option value='5'>4th</option>
            <option value='6'>5th</option>
            <option value='7'>6th</option>
            <option value='8'>7th</option>
            <option value='9'>8th</option>
          </select>
          <div className='invalid-feedback'>Please provide valid Grade. </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <label htmlFor='notes'>
            List all allergies recurring illnesses, injuries, disabilities, etc.
          </label>
          <textarea
            className='form-control'
            placeholder=''
            onChange={e => {
              handleChange(stateKey, 'notes', e.target.value)
            }}
            value={state.notes}
          />
        </div>
      </div>
      <div className='row' style={{ marginTop: '10px' }}>
        <div className='col-md-12'>
          <label htmlFor='additionalNotes'>Any additional notes</label>
          <textarea
            className='form-control'
            onChange={e => {
              handleChange(stateKey, 'additionalNotes', e.target.value)
            }}
            value={state.additionalNotes}
          />
        </div>
      </div>
    </>
  )
}

export { Attendee }
