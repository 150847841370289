/* eslint-disable no-use-before-define */
import React, { useEffect, useContext } from 'react'
import { ReservationContext } from '../../../reducers/ReservationReducer'

const Table = ({ selectedProgram, setErrMsg }: any) => {
  const [reservation, dispatchToReservation] =
    useContext<any>(ReservationContext)

  const getSessionFromSelectedProgram = (sessionId: any) => {
    const selectedProgramSessionPos = selectedProgram.sessions
      .map((s: any) => {
        return s.id
      })
      .indexOf(sessionId)
    return selectedProgram.sessions[selectedProgramSessionPos]
  }

  // returns true if successful, false otherwise
  const upsertSession = (sessionId: string, optionId: any, option: any) => {
    const session = getSessionFromSelectedProgram(sessionId)
    if (
      session &&
      session.maxAttendees &&
      session.currentAttendees &&
      session.currentAttendees >= session.maxAttendees
    ) {
      setErrMsg('Session is FULL. Please select another session.')
      return false
    }
    // setSelectedSessionIds([...selectedSessionIds, sessionId])
    dispatchToReservation({
      type: 'UPSERT_SESSION',
      programId: selectedProgram.id,
      sessionId,
      session,
      optionId,
      option
    })
    return true
  }
  const removeSession = (sessionId: string) => {
    const sessionPos = reservation.slots
      .map((s: any) => {
        return s.sessionId
      })
      .indexOf(sessionId)
    if (sessionPos > -1) {
      dispatchToReservation({
        type: 'REMOVE_SESSION',
        programId: selectedProgram.id,
        sessionId
      })
    }
  }
  const toggleSession = (sessionId: string) => {
    // find session pos (the right slot) in reservations
    const sessionPos = reservation.slots
      .map((s: any) => {
        return s.sessionId
      })
      .indexOf(sessionId)
    // if session exists, remove it
    if (sessionPos > -1) {
      selectOption(sessionId, 'unselect')
    } else {
      selectOption(sessionId)
    }
  }

  const selectOption = (sessionId: string, optionId?: any) => {
    // find session pos (the right slot) in reservations
    const sessionPos = reservation.slots
      .map((s: any) => {
        return s.sessionId
      })
      .indexOf(sessionId)
    // if optionId is unselect, then set sessionId on map to null
    if (optionId === 'unselect') {
      // if session is selected, unselect it
      if (sessionPos > -1) {
        removeSession(sessionId)
      }
    } else {
      const session = getSessionFromSelectedProgram(sessionId)
      if (session && session.options && session.options.length > 0) {
        const optionPos = session.options
          .map((s: any) => {
            return s.id
          })
          .indexOf(optionId)
        let option: any = {}
        let temp: any = {} // variable to hold options data
        if (optionPos > -1) {
          temp = session.options[optionPos]
          option = {
            optionData: temp,
            startTime:
              temp.startTime !== null ? temp.startTime : session.startTime,
            endTime: temp.endTime !== null ? temp.endTime : session.endTime
          }
        } else {
          temp = session.options[0]
          option = {
            optionData: temp,
            startTime:
              temp.startTime !== null ? temp.startTime : session.startTime,
            endTime: temp.endTime !== null ? temp.endTime : session.endTime
          }
        }
        upsertSession(sessionId, option.optionData.id, option.optionData)
      } else {
        upsertSession(sessionId, null, null)
      }
    }
  }

  useEffect(() => {
    setErrMsg('')
  }, [])

  return (
    <>
      <div className='row' id='tableView'>
        <div className='col-md-12'>
          <table
            className='selectableTable table table-striped table-bordered table-hover'
            id='sessionTable'
            style={{ textAlign: 'center' }}
          >
            <tbody id='sessionTableBody'>
              {/* Removed hard coded banner and include as option in app */}
              {selectedProgram?.title?.includes('Mid-City Summer Camp') && (
                <tr>
                  <td colSpan={4}>
                    <b>
                      **Important Note: This is pre-registration. Mid-City
                      (only) summer camp 2021 location is TBD. Pre-registering
                      reserves your spot once the location is announced.
                    </b>
                  </td>
                </tr>
              )}
              <tr>
                <th>Name</th>
                <th>Start</th>
                <th>End</th>
                <th>Options</th>
              </tr>
              {selectedProgram &&
                selectedProgram.sessions &&
                selectedProgram.sessions.map((s: any) => {
                  const slotPos = reservation.slots
                    .map((s: any) => {
                      return s.sessionId
                    })
                    .indexOf(s.id)

                  let slot: any = {}
                  if (slotPos > -1) {
                    slot = reservation.slots[slotPos]
                  }

                  return (
                    <tr
                      key={s.id}
                      onClick={() => {
                        toggleSession(s.id)
                      }}
                      className={
                        reservation.slots
                          .map((s: any) => {
                            return s.sessionId
                          })
                          .indexOf(s.id) > -1
                          ? 'table-primary'
                          : ''
                      }
                    >
                      <td>{s.title}</td>
                      <td>{s.startTime}</td>
                      <td>{s.endTime}</td>
                      <td>
                        <select
                          id={'session-' + `${s.id}` + '-select'}
                          onClick={e => {
                            e.stopPropagation()
                          }}
                          onChange={e => {
                            selectOption(s.id, e.target.value)
                          }}
                          value={(slot && slot.optionId) || 'unselect'}
                          style={{ maxWidth: '200px' }}
                        >
                          <option value='unselect'>SELECT ONE</option>
                          {s &&
                            s.options &&
                            s.options.map((o: any) => {
                              return (
                                <option key={o.id} value={o.id}>
                                  {o.title}
                                </option>
                              )
                            })}
                        </select>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export { Table }
