import { RootState } from '../../../redux'
import { useSelector } from 'react-redux'
import ArrowRightIcon from './ArrowRightIcon'

const StepsIndicator = () => {
  const { step } = useSelector((state: RootState) => state.steps)
  return <div className="stepsIndicatorContainer">
    <div className={`step ${step >= 1 && 'selectedStep'}`}>
      <div className="stepNumber">1</div>
      <div className="stepName">Parent Information</div>
      <ArrowRightIcon isCurrentStep={step >= 1} />
    </div>
    <div className={`step ${step >= 2 && 'selectedStep'}`}>
      <div className="stepNumber">2</div>
      <div className="stepName">Sessions</div>
      <ArrowRightIcon isCurrentStep={step >= 2} />
    </div>
    <div className={`step ${step >= 3 && 'selectedStep'}`}>
      <div className="stepNumber">3</div>
      <div className="stepName">Discounts</div>
      <ArrowRightIcon isCurrentStep={step >= 3} />
    </div>
    <div className={`step ${step >= 4 && 'selectedStep'}`}>
      <div className="stepNumber">4</div>
      <div className="stepName">Summary</div>
    </div>
  </div>
}

export default StepsIndicator
