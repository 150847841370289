import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { FamilyInfo, ReportRows, SessionPricing, SessionsPerCampers, SiblingDiscountChart, TransportationDiscount } from '../../types/libertyLake'

interface StepsInitialState {
  step: number,
  campers: string[],
  pricingMap?: Record<string, SessionPricing>,
  siblingDiscountChart?: Record<string, SiblingDiscountChart>
  transportationDiscountMap?: Record<string, TransportationDiscount>
  sessionsPerCampers: SessionsPerCampers[]
  libertyLakeData?: ReportRows
  familyInfo: FamilyInfo
}

const initialState: StepsInitialState = {
  step: 1,
  campers: [],
  pricingMap: undefined,
  siblingDiscountChart: undefined,
  sessionsPerCampers: [],
  libertyLakeData: undefined,
  familyInfo: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    secondaryPhone: '',
    address: '',
    occupation: 'None',
    occupationalDiscountPercentage: 0,
    isEarlyPaying: false,
    earlyPayDiscount: 0,
    uniqueSessions: [],
    amTransportationChoice: '',
    pmTransportationChoice: '',
    amTransportationDiscount: 0,
    pmTransportationDiscount: 0,
    familyPrice: 0,

    familySessionsCost: 0,
    totalFamilyDiscount: 0,
    grandTotalFamilyCost: 0
  }
}

const setStepNumber = (state = initialState, action: PayloadAction<number>) => {
  return {
    ...state,
    step: action.payload
  }
}

const setCampers = (state = initialState, action: PayloadAction<string[]>) => {
  return {
    ...state,
    campers: action.payload
  }
}

const setPricingMap = (state = initialState, action: PayloadAction<Record<string, SessionPricing>>) => {
  return {
    ...state,
    pricingMap: action.payload
  }
}

const setSiblingDiscountChart = (state = initialState, action: PayloadAction<Record<string, SiblingDiscountChart>>) => {
  return {
    ...state,
    siblingDiscountChart: action.payload
  }
}

const setTransportationDiscountMap = (state = initialState, action: PayloadAction<Record<string, TransportationDiscount>>) => {
  return {
    ...state,
    transportationDiscountMap: action.payload
  }
}

const setSessionsPerCampers = (state = initialState, action: PayloadAction<SessionsPerCampers[]>) => {
  return {
    ...state,
    sessionsPerCampers: action.payload
  }
}

const setFamilyInfo = (state = initialState, action: PayloadAction<FamilyInfo>) => {
  return {
    ...state,
    familyInfo: action.payload
  }
}

const setLibertyLakeReportRows = (state = initialState, action: PayloadAction<ReportRows>) => {
  return {
    ...state,
    libertyLakeData: action.payload
  }
}

const stepSlice = createSlice({
  name: 'steps',
  initialState,
  reducers: {
    setStepNumber,
    setCampers,
    setSiblingDiscountChart,
    setPricingMap,
    setTransportationDiscountMap,
    setSessionsPerCampers,
    setFamilyInfo,
    setLibertyLakeReportRows
  }
})

export default stepSlice
