/* eslint-disable no-use-before-define */
import React from 'react'

import amexLogo from './amex.jpg'
import masterLogo from './mastercard.jpg'
import visaLogo from './visa.jpg'

const CreditCardForm = ({ data, handleChange }: any) => {
  return (
    <>
      <div
        className='row'
        id='creditCardContainer'
        style={{ margin: '20px 0' }}
      >
        <div id='creditCardID'>
          <div className='creditCardForm'>
            <div className='payment'>
              <form>
                <div className='form-group owner'>
                  <label htmlFor='owner'>Full Name</label>
                  <input
                    type='text'
                    className='form-control'
                    value={data.name}
                    onChange={e => {
                      handleChange('name', e.target.value)
                    }}
                    autoComplete='cc-name'
                    id='cc-name'
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='cvv'>CVV</label>
                  <input
                    type='text'
                    className='form-control'
                    onChange={e => {
                      handleChange('cvv', e.target.value)
                    }}
                    value={data.cvv}
                    autoComplete='cc-csc'
                    id='cc-csc'
                  />
                </div>
                <div className='form-group' id='card-number-field'>
                  <label htmlFor='cardNumber'>Card Number</label>
                  <input
                    id='cardNumber'
                    type='text'
                    className='form-control'
                    onChange={e => {
                      handleChange('num', e.target.value)
                    }}
                    value={data.num}
                    autoComplete='cc-number'
                  />
                </div>
                <div className='form-group' id='expiration-date'>
                  <label>Expiration Date</label>
                  <select
                    id='expMon'
                    style={{ fontSize: '18px' }}
                    onChange={e => {
                      handleChange('expMon', e.target.value)
                    }}
                    value={data.expMon}
                    autoComplete='cc-exp-month'
                  >
                    <option value='01'>January</option>
                    <option value='02'>February</option>
                    <option value='03'>March</option>
                    <option value='04'>April</option>
                    <option value='05'>May</option>
                    <option value='06'>June</option>
                    <option value='07'>July</option>
                    <option value='08'>August</option>
                    <option value='09'>September</option>
                    <option value='10'>October</option>
                    <option value='11'>November</option>
                    <option value='12'>December</option>
                  </select>
                  <select
                    id='expYear'
                    style={{ fontSize: '18px' }}
                    onChange={e => {
                      handleChange('expYear', e.target.value)
                    }}
                    value={data.expYear}
                    autoComplete='cc-exp-year'
                  >
                    <option value='20'> 2020</option>
                    <option value='21'> 2021</option>
                    <option value='22'> 2022</option>
                    <option value='23'> 2023</option>
                    <option value='24'> 2024</option>
                    <option value='25'> 2025</option>
                    <option value='26'> 2026</option>
                    <option value='27'> 2027</option>
                    <option value='28'> 2028</option>
                    <option value='29'> 2029</option>
                    <option value='30'> 2030</option>
                  </select>
                </div>
                <div className='form-group' id='zip-field'>
                  <label htmlFor='zip'>Zip</label>
                  <input
                    type='text'
                    className='form-control'
                    id='zip'
                    onChange={e => {
                      handleChange('zip', e.target.value)
                    }}
                    value={data.zip}
                  />
                </div>
                <div className='form-group' id='credit_cards'>
                  <img src={visaLogo} id='visa' />
                  <img src={masterLogo} id='mastercard' />
                  <img src={amexLogo} id='amex' />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { CreditCardForm }
