/* eslint-disable multiline-ternary */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useContext } from 'react'
import { ReservationContext } from '../../../reducers/ReservationReducer'
import { Table } from '../Table/Table'

const Step2 = ({
  currentStep,
  setCurrentStep,
  categories,
  setSidebarVisible
}: any) => {
  const [reservation] =
    useContext<any>(ReservationContext)

  const [prevNextVisible, setPrevNextVisible] = useState(false)

  const [errMsg, setErrMsg] = useState('')

  const calculateTotal = () => {
    setSidebarVisible(true)
    setPrevNextVisible(true)
  }

  const prev = () => {
    setSidebarVisible(false)
    setCurrentStep(1)
  }
  const next = () => {
    // perform validations before going to the next step
    if (!reservation.slots || reservation.slots.length < 1) {
      return setErrMsg('Please select at least 1 session.')
    }

    setSidebarVisible(false)
    setCurrentStep(3)
  }

  useEffect(() => {
    setErrMsg('')
  }, [])

  return (
    <>
      <div className='container' style={{ marginTop: '25px' }}>
        <br />
        <Table
          selectedProgram={reservation.selectedProgram}
          setErrMsg={setErrMsg}
        />
        <br />
        <div className='col-md-12' style={{ textAlign: 'center' }}>
          {reservation.selectedProgram.view === 'table' && (
            <p>
              *** No same day sign ups! When registering, your start date is no
              earlier than the following business day. Thank you and see you out
              there!
            </p>
          )}
          {reservation.selectedProgram.title ===
            'Hancock Park Elementary Summer Camp' ? (
            <p>
              The final week of summer, August 9-13, is held at Melrose Avenue
              Elementary, which is found on the drop-down menu on the previous
              page!
            </p>
          ) : reservation.selectedProgram.title ===
            'Third Street Elementary Summer Camp' ? (
            <p>
              The final week of summer, August 9-13, is held at Melrose Avenue
              Elementary, which is found on the drop-down menu on the previous
              page!
            </p>
          ) : (
            ''
          )}
        </div>
        <div style={{ color: 'red' }}>{errMsg}</div>
        <div
          className='col-md-12'
          id='calculate-total'
          style={{ textAlign: 'center' }}
        >
          <button
            className='btn btn-default btn-lg'
            style={{
              marginRight: '5px',
              marginTop: '10px',
              borderRadius: 5
            }}
            onClick={() => {
              calculateTotal()
            }}
          >
            Calculate Total
          </button>
        </div>
        <div
          className='col-md-12'
          style={{
            textAlign: 'center',
            display: `${!prevNextVisible ? 'none' : ''}`
          }}
        >
          <button
            className='btn btn-default btn-lg'
            style={{
              marginRight: '5px',
              marginTop: '10px',
              borderRadius: 5
            }}
            onClick={() => {
              prev()
            }}
          >
            Back
          </button>
          <button
            className='btn btn-default btn-lg'
            style={{
              marginRight: '5px',
              marginTop: '10px',
              borderRadius: 5
            }}
            onClick={() => {
              next()
            }}
          >
            Next
          </button>
        </div>
        <br />
      </div>
    </>
  )
}

export { Step2 }
