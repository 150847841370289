import { configureStore } from '@reduxjs/toolkit'
import stepSlice from './slice/steps.slice'
import appSlice from './slice/app.slice'

export const store = configureStore({
  reducer: {
    app: appSlice.reducer,
    steps: stepSlice.reducer
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
