/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useContext } from 'react'

import { ReservationContext } from '../../../reducers/ReservationReducer'
import { Attendee } from '../Forms/Attendee'

const Step3 = ({
  currentStep,
  setCurrentStep,
  programs,
  allRelations,
  setSidebarVisible
}: any) => {
  const [reservation, dispatchToReservation] =
    useContext<any>(ReservationContext)

  const [agreed, setAgreed] = useState(false)

  const [errMsg, setErrMsg] = useState('')

  const prev = () => {
    setCurrentStep(2)
  }
  const next = () => {
    // perform validations before going to the next step
    if (!agreed) {
      return setErrMsg(
        'Please read and agree to the GOT GAME WAIVER for participation'
      )
    }

    for (let i = 1; i <= reservation.attendees; i += 1) {
      const attendee = reservation.form[`attendee_${i}`]
      if (!attendee || !attendee.firstName || attendee.firstName === '') {
        return setErrMsg(`Attendee ${i} first name required.`)
      }
      if (!attendee.lastName || attendee.lastName === '') {
        return setErrMsg(`Attendee ${i} last name required.`)
      }
      if (!attendee.dob || attendee.dob === '') {
        return setErrMsg(`Attendee ${i} date of birth required.`)
      }
      if (!attendee.schoolName || attendee.schoolName === '') {
        return setErrMsg(`Attendee ${i} school name required.`)
      }
    }

    const g1 = reservation.form.g1
    if (!g1 || !g1.firstName || g1.firstName === '') {
      return setErrMsg('Guardian first name required.')
    }
    if (!g1.lastName || g1.lastName === '') {
      return setErrMsg('Guardian last name required.')
    }
    if (!g1.email || g1.email === '') {
      return setErrMsg('Guardian email required.')
    }
    if (!g1.dob || g1.dob === '') {
      return setErrMsg('Guardian date of birth required.')
    }
    if (!g1.phone || g1.phone === '') {
      return setErrMsg('Guardian phone required.')
    }
    if (!g1.address || g1.address === '') {
      return setErrMsg('Guardian street address required.')
    }
    if (!g1.city || g1.city === '') {
      return setErrMsg('Guardian city required.')
    }
    if (!g1.zip || g1.zip === '') {
      return setErrMsg('Guardian zip required.')
    }

    if (!reservation.form || !reservation.form.emergencyName) {
      return setErrMsg('Emergency contact name required.')
    }
    if (!reservation.form.emergencyPhone) {
      return setErrMsg('Emergency contact phone required.')
    }

    // eslint-disable-next-line no-undef
    if (window.innerWidth > 767) {
      setSidebarVisible(true)
    } else {
      setSidebarVisible(false)
    }
    setCurrentStep(4)
  }

  const handleChange = (key: any, field: any, value: any) => {
    dispatchToReservation({
      type: 'UPDATE_FORM',
      key,
      field,
      value
    })
  }

  useEffect(() => {
    // For scrollIntoView from https://gotgamecamp.com
    window.parent?.postMessage?.('scroll', 'https://gotgamecamp.com')
    setErrMsg('')
  }, [])
  return (
    <>
      <div className='container'>
        <br />
        <span id='attendeeForm'>
          <div className='row-md-12'>
            <h3>Attendee(s) Information</h3>
          </div>
          {Array.from(new Array(reservation.attendees), (x, i) => i + 1).map(
            (v: any, i: any) => {
              return (
                <Attendee
                  state={reservation.form[`attendee_${i + 1}`]}
                  key={`attendee_${i + 1}`}
                  stateKey={`attendee_${i + 1}`}
                  attendeeNumber={i + 1}
                  handleChange={handleChange}
                />
              )
            }
          )}
          <hr className='mt-5 mb-5' />
        </span>
        <div className='row' id='guardian' style={{ margin: '20px 0' }}>
          <div className='row-md-12'>
            <h3>Guardian Information</h3>
          </div>
          <div className='row mb-1'>
            <div className='col-md-2 mb-3'>
              <label htmlFor='prefix'>Prefix</label>
              <input
                className='form-control'
                id='g1_prefix'
                type='text'
                onChange={e => {
                  handleChange('g1', 'prefix', e.target.value)
                }}
                value={reservation.form.g1.prefix}
              />
              <div className='invalid-feedback'>
                Please provide valid prefix.
              </div>
            </div>
            <div className='col-md-5 mb-3'>
              <label htmlFor='firstName'>First Name</label>
              <sup>*</sup>
              <input
                className='form-control'
                id='g1_firstName'
                type='text'
                onChange={e => {
                  handleChange('g1', 'firstName', e.target.value)
                }}
                value={reservation.form.g1.firstName}
              />
              <div className='invalid-feedback'>
                Valid first name is required.
              </div>
            </div>
            <div className='col-md-5 mb-3'>
              <label htmlFor='lastName'>Last Name</label>
              <sup>*</sup>
              <input
                className='form-control'
                id='g1_lastName'
                type='text'
                onChange={e => {
                  handleChange('g1', 'lastName', e.target.value)
                }}
                value={reservation.form.g1.lastName}
              />
              <div className='invalid-feedback'>
                Valid last name is required.
              </div>
            </div>
            <div className='col-md-6 mb-3'>
              <label>Phone</label>
              <sup>*</sup>
              <input
                className='form-control'
                type='tel'
                placeholder='XXX-XXX-XXXX'
                onChange={e => {
                  handleChange('g1', 'phone', e.target.value)
                }}
                value={reservation.form.g1.phone}
              />
              <div className='invalid-feedback'>
                Please provide valid Cell Phone number.
              </div>
            </div>
            <div className='col-md-6 mb-3'>
              <label>Secondary Phone</label>
              <input
                className='form-control'
                type='tel'
                placeholder='XXX-XXX-XXXX'
                onChange={e => {
                  handleChange('g1', 'secondaryPhone', e.target.value)
                }}
                value={reservation.form.g1.secondaryPhone}
              />
              <div className='invalid-feedback'>
                Please provide valid Work Phone number.
              </div>
            </div>
            <div className='col-md-9 mb-4'>
              <label htmlFor='email'>Email</label>
              <sup>*</sup>
              <input
                className='form-control'
                id='g1_email'
                type='email'
                placeholder='you@example.com'
                onChange={e => {
                  handleChange('g1', 'email', e.target.value)
                }}
                value={reservation.form.g1.email}
              />
              <div className='invalid-feedback'>
                Please enter a valid email address.
              </div>
            </div>
            <div className='col-md-3 mb-4'>
              <label htmlFor='g1dob'>Date of Birth</label>
              <sup>*</sup>
              <input
                className='form-control'
                id='g1_dob'
                type='date'
                placeholder='mm/dd/yyyy'
                onChange={e => {
                  handleChange('g1', 'dob', e.target.value)
                }}
                value={reservation.form.g1.dob}
              />
              <div className='invalid-feedback'>
                Please enter a date of birth.
              </div>
            </div>
            <div className='col-md-12 mb-3'>
              <label htmlFor='address'>Address</label>
              <sup>*</sup>
              <input
                className='form-control'
                id='g1_streetAddress'
                type='text'
                placeholder='1234 Main St. Unit 123'
                onChange={e => {
                  handleChange('g1', 'address', e.target.value)
                }}
                value={reservation.form.g1.address}
              />
              <div className='invalid-feedback'>Please enter your address.</div>
            </div>
            <div className='col-md-4 mb-3'>
              <label htmlFor='city'>City</label>
              <sup>*</sup>
              <input
                className='form-control'
                id='g1_city'
                type='text'
                onChange={e => {
                  handleChange('g1', 'city', e.target.value)
                }}
                value={reservation.form.g1.city}
              />
              <div className='invalid-feedback'>Please provide valid City.</div>
            </div>
            <div className='col-md-4 mb-3'>
              <label htmlFor='state'>State</label>
              <sup>*</sup>
              <input
                className='form-control'
                id='g1_state'
                type='text'
                onChange={e => {
                  handleChange('g1', 'state', e.target.value)
                }}
                value={reservation.form.g1.state}
              />
              <div className='invalid-feedback'>
                Please provide valid State.
              </div>
            </div>
            <div className='col-md-4 mb-3'>
              <label htmlFor='zip'>Zip</label>
              <sup>*</sup>
              <input
                className='form-control'
                id='g1_zip'
                type='number'
                onChange={e => {
                  handleChange('g1', 'zip', e.target.value)
                }}
                value={reservation.form.g1.zip}
              />
              <div className='invalid-feedback'>Zip code required.</div>
            </div>
          </div>
        </div>
        <div className='row' id='guardian2' style={{ margin: '20px 0' }}>
          <div className='row' style={{ margin: '20px 0' }}>
            <div className='row-md-12'>
              <h3>Guardian 2 Information</h3>
            </div>
            <div className='row mb-1'>
              <div className='col-md-2 mb-3'>
                <label htmlFor='prefix'>Prefix</label>
                <input
                  className='form-control'
                  id='g2_prefix'
                  type='text'
                  onChange={e => {
                    handleChange('g2', 'prefix', e.target.value)
                  }}
                  value={reservation.form.g2.prefix}
                />
                <div className='invalid-feedback'>
                  Please provide valid prefix.
                </div>
              </div>
              <div className='col-md-5 mb-3'>
                <label htmlFor='firstName'>First Name</label>
                <input
                  className='form-control'
                  id='g2_firstName'
                  type='text'
                  onChange={e => {
                    handleChange('g2', 'firstName', e.target.value)
                  }}
                  value={reservation.form.g2.firstName}
                />
                <div className='invalid-feedback'>
                  Valid first name is required.
                </div>
              </div>
              <div className='col-md-5 mb-3'>
                <label htmlFor='lastName'>Last Name</label>
                <input
                  className='form-control'
                  id='g2_lastName'
                  type='text'
                  onChange={e => {
                    handleChange('g2', 'lastName', e.target.value)
                  }}
                  value={reservation.form.g2.lastName}
                />
                <div className='invalid-feedback'>
                  Valid last name is required.
                </div>
              </div>
              <div className='col-md-6 mb-3'>
                <label>Phone</label>
                <input
                  className='form-control'
                  type='tel'
                  placeholder='XXX-XXX-XXXX'
                  onChange={e => {
                    handleChange('g2', 'phone', e.target.value)
                  }}
                  value={reservation.form.g2.phone}
                />
                <div className='invalid-feedback'>
                  Please provide valid Cell Phone number.
                </div>
              </div>
              <div className='col-md-6 mb-3'>
                <label htmlFor='work'>Secondary Phone</label>
                <input
                  className='form-control'
                  type='tel'
                  placeholder='XXX-XXX-XXXX'
                  onChange={e => {
                    handleChange('g2', 'secondaryPhone', e.target.value)
                  }}
                  value={reservation.form.g2.secondaryPhone}
                />
                <div className='invalid-feedback'>
                  Please provide valid Work Phone number.
                </div>
              </div>
              <div className='col-md-9 mb-4'>
                <label htmlFor='email'>Email</label>
                <input
                  className='form-control'
                  id='g2_email'
                  type='email'
                  placeholder='you@example.com'
                  onChange={e => {
                    handleChange('g2', 'email', e.target.value)
                  }}
                  value={reservation.form.g2.email}
                />
                <div className='invalid-feedback'>
                  Please enter a valid email address.
                </div>
              </div>
              <div className='col-md-3 mb-4'>
                <label htmlFor='g2dob'>Date of Birth</label>
                <input
                  className='form-control'
                  id='g2_dob'
                  type='date'
                  placeholder='mm/dd/yyyy'
                  onChange={e => {
                    handleChange('g2', 'dob', e.target.value)
                  }}
                  value={reservation.form.g2.dob}
                />
                <div className='invalid-feedback'>
                  Please enter a date of birth.
                </div>
              </div>
              <div className='col-md-12 mb-3'>
                <label htmlFor='address'>Address</label>
                <input
                  className='form-control'
                  id='g2_streetAddress'
                  type='text'
                  placeholder='1234 Main St. Unit 123'
                  onChange={e => {
                    handleChange('g2', 'address', e.target.value)
                  }}
                  value={reservation.form.g2.address}
                />
                <div className='invalid-feedback'>
                  Please enter your address.
                </div>
              </div>
              <div className='col-md-4 mb-3'>
                <label htmlFor='city'>City</label>
                <input
                  className='form-control'
                  id='g2_city'
                  type='text'
                  onChange={e => {
                    handleChange('g2', 'city', e.target.value)
                  }}
                  value={reservation.form.g2.city}
                />
                <div className='invalid-feedback'>
                  Please provide valid City.
                </div>
              </div>
              <div className='col-md-4 mb-3'>
                <label htmlFor='state'>State</label>
                <input
                  className='form-control'
                  id='g2_state'
                  type='text'
                  onChange={e => {
                    handleChange('g2', 'state', e.target.value)
                  }}
                  value={reservation.form.g2.state}
                />
                <div className='invalid-feedback'>
                  Please provide valid State.
                </div>
              </div>
              <div className='col-md-4 mb-3'>
                <label htmlFor='zip'>Zip</label>
                <input
                  className='form-control'
                  id='g2_zip'
                  type='number'
                  onChange={e => {
                    handleChange('g2', 'zip', e.target.value)
                  }}
                  value={reservation.form.g2.zip}
                />
                <div className='invalid-feedback'>Zip code required.</div>
              </div>
            </div>
          </div>
        </div>
        <span id='additionalGuardian' />
        <hr />
        <div className='row'>
          <div className='col-md-12'>
            <h3>Additional Information</h3>
          </div>
          <div className='col-md-6 mb-3'>
            <label htmlFor='emergencyName'>Emergency Contact Name</label>
            <sup>*</sup>
            <input
              className='form-control'
              id='emergencyName'
              type='text'
              placeholder='Emergency Contact Name'
              onChange={e => {
                handleChange('form', 'emergencyName', e.target.value)
              }}
              value={reservation.form.emergencyName}
            />
          </div>
          <div className='col-md-6 mb-3'>
            <label htmlFor='emergencyPhone'>Emergency Contact Phone</label>
            <sup>*</sup>
            <input
              className='form-control'
              id='emergencyPhone'
              type='text'
              placeholder='Emergency Contact Phone'
              onChange={e => {
                handleChange('form', 'emergencyPhone', e.target.value)
              }}
              value={reservation.form.emergencyPhone}
            />
          </div>
          {/* TODO: Discuss possibly adding a link instead. */}
          <div className='col-md-12'>
            <label htmlFor='terms'>Got Game Waiver for Participation</label>
            <p
              style={{
                minWidth: '100%',
                whiteSpace: 'normal',
                height: '250px',
                overflow: 'auto',
                border: '1px solid black'
              }}
            >
              <b>GOT GAME SPORTS ASSUMPTION OF RISK AND WAIVER OF LIABILITY:</b>
              As a condition to my minor’s participation in services offered by,
              or use of facilities, premises, locations, private residences and
              backyards, public grounds, schools, parks, or equipment owned,
              leased, operated, or otherwise in authorized control or use
              (“Locations”) by Got Game Sports, LLC, its subsidiaries or
              affiliates (collectively, “Got Game”), and in consideration
              thereof, the undersigned person, on behalf of himself or herself
              and his or her representatives, minor child (if a “Minor Child
              Authorization” is attached hereto), affiliates, heirs, successors
              and permitted assigns (collectively “I” and the forms “me” and
              “my”), hereby represents, warrants and covenants as follows (the
              “Release”):
              <br />
              <br />
              I am aware that participation in any and all activities of any
              nature or kind, including, but not limited to, the practice of
              physical activity, group games, one-on-one’s, outdoor and indoor
              academic, creative, and artistic activities, Got Game’s sports
              programs, classes, camps and any teaching related thereto or under
              the guidance of any Got Game coach, teacher or representative, and
              use of any and all Locations, equipment, amenities and services
              thereof (collectively, “Covered Activities”) involves inherent
              risks including but in no way limited to: moderate and severe
              personal injury, property damage, disability, dismemberment, heart
              attack, stress, paralysis, seizures, or death, and sickness or
              disease including, without limitation, COVID-19. I acknowledge
              that I am knowingly and voluntarily participating in the Covered
              Activities with knowledge of the risks and hazards inherent in or
              potentially caused by such Covered Activities. I acknowledge that
              I have been given the opportunity to ask questions concerning the
              Covered Activities, and all such questions have been answered to
              my satisfaction. I understand that physical examinations are
              recommended before commencing any program containing physical
              activities, and I further represent that I am in good physical and
              mental health and that I do not suffer from any physical or mental
              impairment, disease, ailment, injury or other condition that
              limits my ability to participate in the Covered Activities.
              <br />
              <br />
              I acknowledge that my participation in any of the Covered
              Activities is completely voluntary, that I am fully aware of the
              risks and hazards associated with the Covered Activities, and
              hereby elect to voluntarily assume all such risks and voluntarily
              attend and/or participate in the Covered Activities as evidenced
              by execution of this Release. IN CONSIDERATION OF PARTICIPATING IN
              THE COVERED ACTIVITIES I HEREBY PERSONALLY AND VOLUNTARILY ASSUME
              ALL RISKS THEREOF, INCLUDING, WITHOUT LIMITATION, PARTICIPATION IN
              ANY GOT GAME CLASS, INSTRUCTION, GROUP SPORTS PROGRAMS, ONE-ON-ONE
              SESSION, CAMPS AND ANY ACTIVITY OF ANY NATURE OR KIND, WHETHER
              FORESEEN OR UNFORESEEN, KNOWN OR UNKNOWN, THAT MAY OCCUR WHILE I
              AM PARTICIPATING IN THE COVERED ACTIVITIES.
              <br />
              <br />
              I, FOR MYSELF, AND ON BEHALF OF MY HEIRS, EMPLOYERS, AFFILIATES,
              ASSIGNS, PERSONAL REPRESENTATIVES AND NEXT OF KIN, HEREBY RELEASE,
              WAIVE, HOLD HARMLESS, AND PROMISE NOT TO SUE GOT GAME OR ANY OF
              ITS AFFILIATES OR SUBSIDIARIES OR ANY OF THEIR RESPECTIVE
              EMPLOYEES, STAKEHOLDERS, MEMBERS, OFFICERS, DIRECTORS, MANAGERS,
              AGENTS, REPRESENTATIVES, SUCCESSORS, OR ASSIGNS (COLLECTIVELY, THE
              “RELEASED PARTIES”) IN CONNECTION WITH ANY ILLNESS, INJURY AND/OR
              PROPERTY LOSS (INCLUDING ATTORNEYS’ FEES AND COSTS) ARISING FROM
              OR IN CONNECTION WITH MY PARTICIPATION IN ANY OF THE COVERED
              ACTIVITIES, WHETHER CAUSED BY AN ACT OR OMISSION, INCLUDING
              NEGLIGENCE OF THE RELEASED PARTIES OR OTHERWISE, TO THE FULLEST
              EXTENT PERMITTED BY LAW, including without limitation, any claims
              (including attorneys’ fees and costs) arising out of or in
              connection with the following: (i) any illness, injury to me or to
              any property belonging to me arising as a result of my active or
              passive participation in the Covered Activities, including,
              without limitation, any use of the Locations or premises or any
              such activities at the instruction or under the supervision of any
              Got Game coach, personnel, or authorized representative; or (ii)
              any actions or omissions by my or any third party. This waiver and
              release of claims shall extend to all claims against any Released
              Party that were or could have been brought in any foreign or
              domestic forum, judicial or administrative, by or on behalf of me.
              I understand that my rights under California Civil Code §1542 or
              by any law of any state or territory of the United States or other
              jurisdiction, or principle of common law, which is similar,
              comparable or equivalent to §1542 of the California Civil Code,
              with respect to any claims against the Released Parties are
              expressly waived. California Civil Code §1542 provides:
              <br />
              <br />
              “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS THAT THE CREDITOR OR
              RELEASING PARTY DOES NOT KNOW OR EXPECT TO EXIST IN HIS OR HER
              FAVOR AT THE TIME OF EXECUTION OF THE RELEASE AND THAT, IF KNOWN
              BY HIM OR HER, MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT
              WITH THE DEBTOR RELEASED PARTY.”
              <br />
              <br />
              I further agree to indemnify and hold harmless any and all of the
              Released Parties from any and all liabilities, claims, demands,
              expenses, damages, losses, causes of action, attorneys’ fees and
              costs of defense for, or on account of, death, personal injury,
              property damage and any other loss of any kind resulting from or
              related to: (i) my participation in the Covered Activities; (ii)
              my presence at any Location; or (iii) my breach of the terms and
              conditions of this Release.
              <br />
              <br />
              I grant the Released Parties permission to authorize emergency
              medical treatment as the Released Parties deem appropriate and
              agree that such authorization by the Released Parties shall be
              subject to the terms of this Release. I understand and agree that
              the Released Parties assume no responsibility for any injury or
              damage that might arise out of or in connection with such
              authorized emergency medical treatment. I accept personal
              responsibility for the payment of all of my own medical and
              emergency expenses in the event of accident, illness, or other
              incapacitation, regardless of whether or not I have authorized
              such expenses at the time they are incurred.
              <br />
              <br />
              I grant Got Game permission to use any and all photographs taken
              by Got Game or its agents or employees, or submitted by me to Got
              Game (hereinafter “Photographs”) in any media (including, but not
              limited to, print, internet, film, television and no matter how
              distributed or published) for any purpose, including, but not
              limited to, advertising, promoting, and marketing of Got Game and
              the Got Game services sold and marketed by Got Game. I agree that
              this authorization to use Photographs may be assigned by Got Game
              to any other party, may be combined with other Photographs,
              sounds, text and graphics, and may be manipulated, cropped,
              altered or modified in Got Game’s sole discretion. I agree not to
              make any monetary assessment against Got Game in exchange for the
              release under this section. I hereby release and forever discharge
              Got Game from any and all liability and from any damages. I
              further acknowledge and agree that this release is binding upon my
              heirs and assigns and that the release is irrevocable unless
              mutually agreed upon by me and Got Game.
              <br />
              <br />
              I acknowledge that I have been urged to avoid bringing valuables
              to any Got Game Location or premises and that Got Game will not be
              liable for the loss of, theft of, or damage to my personal
              property, including, without limitation, items left in bathrooms,
              or anywhere else on Got Game’s premises. I acknowledge that no
              portion of any fees paid by me to Got Game is in consideration for
              the safeguarding of valuables.
              <br />
              <br />
              I understand that this Release extends forever into the future and
              will have full force and legal effect each and every time I visit
              any Got Game Location, or otherwise participate in Covered
              Activities.
              <br />
              <br />
              I further state that I am of lawful age and legally competent to
              sign this Release (or, if I am under 18 years old, I have directed
              my parent or legal guardian to sign this Release on my behalf). I
              have carefully reviewed this Release, fully understand the terms
              and conditions hereof, and have had the opportunity to consult
              with legal representation, or have knowingly chosen not to do so,
              prior to entering into this Release. I understand that the terms
              herein are contractual and not a mere recital, and that I have
              signed this Release of my own free act, and with knowledge that I
              hereby agree to waive my legal rights. This Release constitutes
              the entire agreement between the parties pertaining to the subject
              matter hereof and supersedes all prior and contemporaneous
              agreements, whether oral, written or implied, between me and Got
              Game concerning the subject matter hereof. This Release shall be
              amended or modified only through a written agreement signed by me
              and Got Game. No statements other than within this Release shall
              be relevant to reduce this Release’s effect and enforcement on me.
              This Release is binding upon and shall insure to the benefit of
              the Released Parties. This Release is not intended to waive any
              rights I may have under Title 2.5, Sections 1812.80 – 1812.98 of
              the California Civil Code.
              <br />
              <br />
              The laws of the State of California shall govern: (i) the validity
              and interpretation of this Release; (ii) the performance by me and
              Got Game of our respective duties and obligations hereunder; and
              (iii) all other causes of action (whether sounding in contract or
              in tort) arising out of or relating in any manner this Release.
              <br />
              <br />
              Any and all disputes or claims (including, but not limited to,
              third-party claims) arising under, concerning, or relating to this
              Release, its interpretation, its validity (including, but not
              limited to, any claim that all or any part of this Release is void
              or voidable), or the subject matter hereof will be resolved by
              confidential and binding arbitration. The arbitration shall be
              conducted by a single arbitrator in accordance with the
              arbitration rules of the American Arbitration Association. The
              arbitration, including the rendering of the award, shall take
              place in Los Angeles, California, United States, and shall be the
              exclusive forum for resolving any such dispute, controversy, or
              claim. Disputes about arbitration procedure shall be resolved by
              the arbitrator. The arbitrator may proceed to an award
              notwithstanding the failure of the other party to participate in
              the proceedings. The decision of the arbitrator shall be binding
              upon the parties hereto, and the expense of the arbitration shall
              be split by the parties and each party shall be responsible for
              their respective attorneys’ fees and expenses. The decision of the
              arbitrator shall be the sole and exclusive remedy of the parties
              and shall be enforceable in any court of competent jurisdiction. I
              UNDERSTAND THAT BY AGREEING TO ARBITRATION IN THE EVENT OF A
              DISPUTE BETWEEN ME AND ANY RELEASED PARTIES, I AM EXPRESSLY
              WAIVING MY RIGHT TO REQUEST A TRIAL BY JURY IN A COURT OF LAW. If,
              notwithstanding the arbitration provisions of this Release, a
              party shall succeed in bringing an action relating to any matter
              or dispute in connection with this Release in a court of law, then
              the venue for resolution of such matter or dispute shall be the
              State or Federal Courts located in Los Angeles, California.
              <br />
              <br />
              Any provision of this Release that is prohibited or unenforceable
              in California or in any other jurisdiction shall, as to such
              jurisdiction, be ineffective to the extent of such prohibition or
              unenforceability without invalidating the remaining provisions
              hereof, and any such prohibition or unenforceability in any
              jurisdiction shall not invalidate or render unenforceable such
              provision in any other jurisdiction. I acknowledge that it is my
              intention to provide a release of claims to the Released Parties
              to the fullest extent permitted by law. To the degree that an
              arbitrator or court determines that any provision of this Release
              is partly or wholly unenforceable, such provision shall be
              re-written by the arbitrator, court or other tribunal interpreting
              the same such that it becomes enforceable and most closely
              approximates the intent of the parties hereto.
              <br />
              <br />
              By acknowledging this document via electronic means, I am
              accepting this Release. I understand that I may be found by a
              court of law to have forever waived any right that I or my
              child(ren) may have to maintain any action against the Released
              Parties on the basis of any claim from which I have released such
              Released Parties herein. I have had a reasonable and sufficient
              opportunity to read and understand this entire document and
              consult with legal counsel or have voluntarily waived my right to
              do so. I knowingly and voluntarily agree to be bound by all terms
              and conditions set forth herein. I understand and agree that I may
              be asked to update this waiver on an annual basis and further
              hereby agree to conduct such a transaction by electronic means. By
              providing my phone number and email address to Got Game, I
              expressly consent to receive phone calls and emails from Got Game.
              Got Game reserves the right to review my driver’s license and/or
              other forms of identification in order to verify my identity.
              <br />
              <br />
              BY CLICKING “I AGREE,” “SUBMIT,” OR OTHERWISE, I ACKNOWLEDGE THAT
              I HAVE READ AND FULLY UNDERSTOOD ALL OF THE TERMS OF THIS RELEASE
              AND THAT I AM VOLUNTARILY GIVING UP SUBSTANTIAL LEGAL RIGHTS,
              INCLUDING THE RIGHT TO SUE THE COMPANY FOR CLAIMS, WHETHER KNOWN
              OR UNKNOWN, ARISING OUT OF MY PARTICIPATION IN THE ACTIVITIES. I
              AM AT LEAST EIGHTEEN (18) YEARS OF AGE AND FULLY COMPETENT.
              <br />
              <br />
              MINOR CHILD AUTHORIZATION
              <br />
              <br />
              BY CLICKING “I AGREE,” “SUBMIT,” OR OTHERWISE, I represent and
              warrant that I am the parent (custodial parent, if applicable) or
              legal guardian of the minor child that I have identified as the
              Minor(s) in the Got Game program sign up form. I have read and
              thoroughly understand the Release provided by Got Game to which
              this authorization applies, and I acknowledge that all capitalized
              terms in this authorization have the meanings ascribed thereto in
              the Release. I hereby expressly consent to Minor’s participation
              in the Covered Activities and bind Minor(s) to the terms and
              conditions contained in the Release. Further, I understand and
              acknowledge all of the following:
              <br />
              <br />
              <ul>
                <li>
                  there are inherent health and safety risks associated with
                  participation in Got Game’s camps and programs at Got Game’s
                  Locations; and
                </li>
                <li>
                  {' '}
                  I am fully responsible for Minor’s actions while Minor is on
                  Got Game’s premises, including, without limitation, any and
                  all damages caused by Minor to Got Game or any third party.
                </li>
              </ul>
            </p>
          </div>
          <div className='col-md-12'>
            <div className='form-check'>
              <input
                className='form-check-input'
                id='agree'
                type='checkbox'
                value={agreed.toString()}
                onChange={e => {
                  setAgreed(!!e.target.value)
                }}
              />
              <label className='form-check-label' htmlFor='agree'>
                I have read and I accept the Got Game Waiver for participation.
              </label>
            </div>
          </div>
        </div>
        <br style={{ margin: '20px 0' }} />
        <div style={{ color: 'red' }}>{errMsg}</div>
        <div className='col-md-12' style={{ textAlign: 'center' }}>
          <button
            className='btn btn-default btn-lg'
            style={{
              marginRight: '5px',
              marginTop: '10px',
              borderRadius: 5
            }}
            onClick={() => {
              prev()
            }}
          >
            Back
          </button>
          <button
            className='btn btn-default btn-lg'
            style={{
              marginRight: '5px',
              marginTop: '10px',
              borderRadius: 5
            }}
            onClick={() => {
              next()
            }}
          >
            Next
          </button>
        </div>
        <br />
      </div>
    </>
  )
}

export { Step3 }
