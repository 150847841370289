import { useState } from 'react'
import arrowRight from '../../../../assets/icons/arrowRight.svg'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../../redux'
import stepSlice from '../../../../redux/slice/steps.slice'
import { FamilyInfo, SessionsPerCampers } from '../../../../types/libertyLake'

const Step1 = () => {
  const dispatch: AppDispatch = useDispatch()
  const campersOptions = [1, 2, 3, 4, 5]
  const { campers, familyInfo } = useSelector((state: RootState) => state.steps)
  const [camperDropdown, showCamperDropdown] = useState(false)

  return <>
    <div className='step1'>
      <div className="row">
        <div className="inputFieldArea">
          <label className='fieldLabel'>Your First Name</label>
          <input
            type="text"
            className='inputField'
            placeholder='John'
            value={familyInfo.firstName}
            onChange={(e) => {
              const updatedInfo: FamilyInfo = {
                ...familyInfo,
                firstName: e.target.value
              }
              dispatch(stepSlice.actions.setFamilyInfo(updatedInfo))
            }}
          />
        </div>
        <div className="inputFieldArea">
          <label className='fieldLabel'>Your Last Name</label>
          <input
            type="text"
            className='inputField'
            placeholder='Doe'
            value={familyInfo.lastName}
            onChange={(e) => {
              const updatedInfo: FamilyInfo = {
                ...familyInfo,
                lastName: e.target.value
              }
              dispatch(stepSlice.actions.setFamilyInfo(updatedInfo))
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="inputFieldArea">
          <label className='fieldLabel'>Your Email</label>
          <input
            type="email"
            className='inputField'
            placeholder='email@example.com'
            value={familyInfo.email}
            onChange={(e) => {
              const updatedInfo: FamilyInfo = {
                ...familyInfo,
                email: e.target.value
              }
              dispatch(stepSlice.actions.setFamilyInfo(updatedInfo))
            }}
          />
        </div>
        <div className="inputFieldArea">
          <label className='fieldLabel'>Your Phone Number</label>
          <input
            type="text"
            className='inputField'
            placeholder='987 654 3210'
            value={familyInfo.phone}
            onChange={(e) => {
              const updatedInfo: FamilyInfo = {
                ...familyInfo,
                phone: e.target.value
              }
              dispatch(stepSlice.actions.setFamilyInfo(updatedInfo))
            }}
          />
        </div>
      </div>
      <div className="inputFieldArea">
        <label className='fieldLabel'>Select Number of Camper(s)</label>
        <div
          className="selectInputFieldArea"
          onClick={() => showCamperDropdown(true)}
        >
          {campers.length > 0 ? campers.length : 'Select...'}
          <img className='arrowIcon' src={arrowRight} />
        </div>
        {camperDropdown
          ? (
            <div className="dropdownContainer">
              {campersOptions.map((noOfCamper: number, index: number) => (
                <div
                  className='dropdownOption'
                  key={`${noOfCamper}:${index}`}
                  onClick={() => {
                    const camperList = []
                    const sessionList: SessionsPerCampers[] = []
                    for (let i = 0; i < noOfCamper; i++) {
                      camperList.push(`Camper ${i + 1}`)
                      sessionList.push({
                        camperId: `Camper ${i + 1}`,
                        grade: '',
                        weeks: [],
                        siblingDiscountPercentage: 0,
                        multiWeekDiscount: 0,
                        eachCamperPrice: 0
                      })
                    }
                    dispatch(stepSlice.actions.setSessionsPerCampers(sessionList))
                    dispatch(stepSlice.actions.setCampers(camperList))
                    showCamperDropdown(false)
                  }}
                >
                  {noOfCamper}
                </div>
              ))}
            </div>
          )
          : ''}
      </div>
    </div>
    {camperDropdown && <div className='backDrop' onClick={() => showCamperDropdown(false)} />}
  </>
}

export default Step1
