import { useEffect } from 'react'
import StepsIndicator from '../../components/LibertyLake/StepsIndicator/StepsIndicator'
import Footer from '../../components/LibertyLake/Footer/Footer'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../redux'
import Step1 from '../../components/LibertyLake/Steps/Step1/Step1'
import Step2 from '../../components/LibertyLake/Steps/Step2/Step2'
import Step3 from '../../components/LibertyLake/Steps/Step3/Step3'
import axios, { AxiosResponse } from 'axios'
import stepSlice from '../../redux/slice/steps.slice'
import Step4 from '../../components/LibertyLake/Steps/Step4/Step4'
import appSlice from '../../redux/slice/app.slice'
import logo from '../../assets/icons/libertyLakeLogo.png'
import { config } from '../../config'

const LibertyLake = () => {
  const dispatch: AppDispatch = useDispatch()
  const { step } = useSelector((state: RootState) => state.steps)
  const { isLoading } = useSelector((state: RootState) => state.app)

  const renderRespectiveStep = () => {
    if (step === 1) {
      return <Step1 />
    } else if (step === 2) {
      return <Step2 />
    } else if (step === 3) {
      return <Step3 />
    } else if (step === 4) {
      return <Step4 />
    } else {
      return null
    }
  }

  const fetchRowsFromGoogleSheet = async () => {
    dispatch(appSlice.actions.setLoading(true))
    try {
      const response: AxiosResponse = await axios({
        url: config.libertyLakeSpreadsheetUrl,
        method: 'GET',
        params: {
          apiKey: config.libertyLakeApiKey
        }
      })

      if (
        response.data.msg === 'Incorrect api key' ||
        (response.status !== 200 &&
          response.statusText !== 'OK' &&
          !response?.data?.success)
      ) {
        console.error('Error getting rows from GoogleSheet', response.data)
        throw new Error(
          `Error getting rows from GoogleSheet: ${response.data as string}`
        )
      }
      dispatch(stepSlice.actions.setPricingMap(response.data.reportRows.Pricing))
      dispatch(stepSlice.actions.setSiblingDiscountChart(response.data.reportRows.Discount['Sibling Number']))
      dispatch(stepSlice.actions.setTransportationDiscountMap(response.data.reportRows.Discount.Transportation))
      dispatch(stepSlice.actions.setLibertyLakeReportRows(response.data?.reportRows))
      dispatch(appSlice.actions.setLoading(false))
      return response.data?.reportRows
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  useEffect(() => {
    fetchRowsFromGoogleSheet()
  }, [])

  return <>
    <div className={'libertyLake'}>
      <div className="contentArea">
        <div className="title">
          <img src={logo} className='logo' />
          Liberty Lake Pricing Calculator
        </div>
        <StepsIndicator />
        <div className='stepsContainer'>
          {renderRespectiveStep()}
        </div>
        <Footer />
      </div>
      {isLoading && <div className='loadingPanel'>
        <div className="lds-ripple"><div></div><div></div></div>
      </div>}
    </div>
  </>
}

export default LibertyLake
