/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useContext, Fragment } from 'react'

import { ReservationContext } from '../../../reducers/ReservationReducer'
import { Category, Program } from '../types'

const Step1 = ({
  accountId,
  currentStep,
  setCurrentStep,
  categories,
  resetBar,
  setResetBar
}: any) => {
  const [reservation, dispatchToReservation] =
    useContext<any>(ReservationContext)

  const [errMsg, setErrMsg] = useState('')

  const selectProgram = (program: any) => {
    dispatchToReservation({
      type: 'SELECT_PROGRAM',
      programId: program.id,
      program
    })
    // Reset the Progress Bar
    setResetBar(true)
  }
  const selectAttendees = (attendees: any) => {
    dispatchToReservation({ type: 'UPDATE_ATTENDEES', attendees })

    // Reset the Progress Bar
    setResetBar(true)
  }
  const next = () => {
    // perform validations before going to the next step
    if (!reservation || !reservation.selectedProgram) {
      return setErrMsg('Please select a program.')
    }
    if (reservation.selectedProgram.title === 'Birthday Party') {
      selectAttendees(1)
    } else if (
      !reservation ||
      !reservation.attendees ||
      reservation.attendees < 1
    ) {
      return setErrMsg('Please select number of attendees.')
    }
    setCurrentStep(2)
  }

  const renderByCategories = () => {
    return categories.map((category: Category) => {
      return (
        <Fragment key={category.title}>
          <li>
            <h3 className="dropdown-header">{category.title}</h3>
          </li>
          {category.programs.map((p: Program) => {
            return (
              <li key={p.title}>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => {
                    selectProgram(p)
                  }}
                  style={{
                    paddingLeft: '2rem'
                  }}
                >
                  {p.title}
                </a>
              </li>
            )
          })}
        </Fragment>
      )
    })
  }

  useEffect(() => {
    setErrMsg('')
  }, [])

  return (
    <>
      <div className="container" style={{ marginTop: '55px' }}>
        <div className="row">
          <div className="col-md-12" style={{ textAlign: 'center' }}>
            <div
              className="input-group mb-3"
              style={{ justifyContent: 'center' }}
            >
              <button
                className="btn btn-podsoft btn-lg dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {reservation &&
                reservation.selectedProgram &&
                reservation.selectedProgram.title
                  ? reservation.selectedProgram.title
                  : 'SELECT A PROGRAM'}
              </button>
              <ul className="dropdown-menu">{renderByCategories()}</ul>
            </div>
            {reservation?.selectedProgram?.title !== 'Birthday Party' && (
              <div
                className="input-group mb-3"
                style={{ marginTop: '50px', justifyContent: 'center' }}
              >
                <button
                  className="btn btn-podsoft btn-lg dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {reservation &&
                  reservation.attendees &&
                  reservation.attendees > 0
                    ? `${reservation.attendees} ATTENDEE(S)`
                    : 'NUMBER OF ATTENDEE(S)'}
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => {
                        selectAttendees(1)
                      }}
                    >
                      1
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => {
                        selectAttendees(2)
                      }}
                    >
                      2
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => {
                        selectAttendees(3)
                      }}
                    >
                      3
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <br />
        <div style={{ color: 'red' }}>{errMsg}</div>
        <div className="col-md-12" style={{ textAlign: 'center' }}>
          <button
            className="btn btn-default btn-lg"
            style={{
              marginRight: '5px',
              marginTop: '10px',
              borderRadius: 5
            }}
            onClick={() => {
              next()
            }}
          >
            Next
          </button>
        </div>
      </div>
    </>
  )
}

export { Step1 }
