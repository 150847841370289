import { useState, useEffect } from 'react'
import arrowRight from '../../../../assets/icons/arrowRight.svg'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../../redux'
import { FamilyInfo, SessionsPerCampers } from '../../../../types/libertyLake'
import stepSlice from '../../../../redux/slice/steps.slice'

const Step2 = () => {
  const dispatch: AppDispatch = useDispatch()
  const gradeOptions = ['Pre thru K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']

  const { campers, sessionsPerCampers, libertyLakeData, familyInfo, pricingMap, siblingDiscountChart } = useSelector((state: RootState) => state.steps)

  const [multiWeekDiscount, setMultiWeekDiscount] = useState<any>(null)

  const [gradeDropdown, showGradeDropdown] = useState(false)
  const [transportationDropdown, showTransportationDropdown] = useState(false)
  const [gradeSelectId, setGradeSelectId] = useState('')

  useEffect(() => {
    if (libertyLakeData) {
      setMultiWeekDiscount(libertyLakeData.Discount['Multi-Week'])
    }
  }, [libertyLakeData])

  useEffect(() => {
    let familyCost = 0
    const allFamilyWeeks: string[] = []
    // calculate base price after deducting pre-school discount and multi-week discount for each camper
    const newSessionsPerCampers: SessionsPerCampers[] = []
    sessionsPerCampers.forEach(sessionPerCamper => {
      let eachCamperPrice = 0
      sessionPerCamper.weeks.forEach((week: string) => {
        allFamilyWeeks.push(week)
        eachCamperPrice += (pricingMap && pricingMap[week].price) || 0
      })
      eachCamperPrice -= sessionPerCamper.multiWeekDiscount
      if (sessionPerCamper.grade === 'Pre thru K') {
        eachCamperPrice = eachCamperPrice * 0.90
      }
      newSessionsPerCampers.push({
        ...sessionPerCamper,
        eachCamperPrice
      })
    })
    // sort campers by their highest base price for sibling discount percentage
    newSessionsPerCampers.sort((a, b) => b.eachCamperPrice - a.eachCamperPrice)
    // add sibling discount percentage according to base Price
    newSessionsPerCampers.forEach((sessionsPerCamper: SessionsPerCampers, i: number) => {
      const siblingDiscountPercentage = (siblingDiscountChart && siblingDiscountChart[`${i + 1}`].Percentage) || 0
      familyCost += sessionsPerCamper.eachCamperPrice * (1 - siblingDiscountPercentage)
    })

    const uniqueSessions = new Set([...allFamilyWeeks])
    const updatedFamilyInfo: FamilyInfo = {
      ...familyInfo,
      familyPrice: familyCost,
      uniqueSessions: Array.from(uniqueSessions)
    }
    dispatch(stepSlice.actions.setFamilyInfo(updatedFamilyInfo))
  }, [sessionsPerCampers])

  const getMultiWeekDiscountAmount = (numberOfWeek: number): number => {
    return multiWeekDiscount[numberOfWeek.toString()]?.Amount || 0
  }

  const returnAllCamperSessionForms = () => {
    return sessionsPerCampers.map((sessionPerCamper: SessionsPerCampers, i: number) => (
      <div className='attendeeForm' key={i}>
        <h3 className='attendeeFormTitle'>{sessionPerCamper.camperId}</h3>
        <div className="attendeeFormRow">
          <div className="inputFieldColumns">
            {/* Grade */}
            <div className="inputFieldArea">
              <label className='fieldLabel'>Select Grade (As of September, 2025)</label>
              <div
                className="selectInputFieldArea"
                onClick={() => {
                  showGradeDropdown(true)
                  setGradeSelectId(campers[i])
                }}
              >
                {sessionPerCamper.grade.length ? sessionPerCamper.grade : 'Select...'}
                <img className='arrowIcon' src={arrowRight} />
              </div>
              {gradeDropdown && gradeSelectId === campers[i]
                ? (
                  <div className="dropdownContainer">
                    {gradeOptions.map((grade: string, index: number) => (
                      <div
                        className='dropdownOption'
                        key={`${grade}:${index}`}
                        onClick={() => {
                          const otherSessions = sessionsPerCampers.filter(sPerA => sPerA.camperId !== sessionPerCamper.camperId)
                          const newData: SessionsPerCampers = {
                            ...sessionPerCamper,
                            grade
                          }
                          const newSortedData: SessionsPerCampers[] = [newData, ...otherSessions].sort((a, b) => {
                            return a.camperId.localeCompare(b.camperId)
                          })
                          dispatch(stepSlice.actions.setSessionsPerCampers(newSortedData))
                          showGradeDropdown(false)
                        }}
                      >
                        {grade}
                      </div>
                    ))}
                  </div>
                )
                : ''}
            </div>
          </div>

          <div className="sessionWeeksColumn">
            <label className='fieldLabel'>Select Weeks/Sessions</label>
            <div className="sessionsWeeksContainer">
              {pricingMap && Object.keys(pricingMap).map((session: string, i: number) => (
                <div className='sessionRow' key={i}>
                  <div className="sessionWeek">
                    <div className='checkboxArea'>
                      <input type='checkbox' checked={sessionPerCamper.weeks.includes(session)} className='checkbox' onChange={() => { }} />
                      <label className='checkmark' onClick={() => {
                        const otherSessions = sessionsPerCampers.filter(sPerA => sPerA.camperId !== sessionPerCamper.camperId)
                        const allWeeks = sessionPerCamper.weeks
                        let newWeeks: string[] = []
                        if (allWeeks.includes(session)) {
                          newWeeks = allWeeks.filter(aw => aw !== session)
                        } else {
                          newWeeks.push(...allWeeks, session)
                        }
                        const newData: SessionsPerCampers = {
                          ...sessionPerCamper,
                          weeks: newWeeks.sort((a, b) => a.localeCompare(b)),
                          multiWeekDiscount: getMultiWeekDiscountAmount(newWeeks.length)
                        }
                        const newSortedData: SessionsPerCampers[] = [newData, ...otherSessions].sort((a, b) => {
                          return a.camperId.localeCompare(b.camperId)
                        })
                        dispatch(stepSlice.actions.setSessionsPerCampers(newSortedData))
                      }} />
                    </div>
                    <div className="sessionColumn">
                      {session}
                      <div className='date'>{(pricingMap && pricingMap[session.trim()].date) || ''}</div>
                    </div>
                    <div className='pricing'>${(pricingMap && pricingMap[session.trim()].price) || ''}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    ))
  }

  return <>
    <div className='step2'>
      {returnAllCamperSessionForms()}
    </div>
    {(gradeDropdown || transportationDropdown) && <div className='backDrop' onClick={() => {
      showGradeDropdown(false)
      showTransportationDropdown(false)
    }} />}
  </>
}

export default Step2
