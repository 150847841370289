/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useContext } from 'react'
import valid from 'card-validator'
import { ReservationContext } from '../../../reducers/ReservationReducer'
import { API } from '../../../api/API'
import { CreditCardForm } from '../Payment/CreditCardForm'
import { ACHForm } from '../Payment/ACHForm'

const Step4 = ({
  accountId,
  currentStep,
  setCurrentStep,
  programs,
  setSidebarVisible,
  acceptPayments,
  getFilledSlots
}: any) => {
  const [agreed, setAgreed] = useState(false)
  const [reservation] = useContext<any>(ReservationContext)
  const [paymentStart, setPaymentStart] = useState<boolean>(false)
  const [paymentType] = useState('creditCard')
  const [dataObj, setDataObj] = useState<any>({
    creditCard: {
      name: '',
      num: '',
      expMon: '01',
      expYear: '20',
      cvv: '',
      zip: '',
      err: ''
    },
    ach: {}
  })
  const [errMsg, setErrMsg] = useState('')

  const handleCreditCardChange = (key: any, value: any) => {
    dataObj.creditCard[key] = value
    setDataObj({ ...dataObj, creditCard: { ...dataObj.creditCard } })
  }

  const prev = () => {
    setSidebarVisible(false)
    setCurrentStep(3)
  }
  const next = async () => {
    if (!agreed) {
      return setErrMsg(
        'Please read and agree to the PROGRAM TERMS AND CONDITIONS'
      )
    }
    if (dataObj.creditCard.err) {
      return setErrMsg(dataObj.creditCard.err)
    }
    if (!dataObj.creditCard.name) {
      return setErrMsg('Full Name must be filled.')
    }
    if (!dataObj.creditCard.num) {
      return setErrMsg('Credit card number must be filled.')
    }
    if (!dataObj.creditCard.expMon || !dataObj.creditCard.expYear) {
      return setErrMsg('Expiry date must be filled.')
    }
    if (!dataObj.creditCard.cvv) {
      return setErrMsg('CVV must be filled.')
    }
    if (!dataObj.creditCard.zip) {
      return setErrMsg('Zip code must be filled.')
    }

    const validNum = valid.number(dataObj.creditCard.num)
    if (!validNum || !validNum.isValid) {
      return setErrMsg('Please enter a valid credit card number.')
    }
    setPaymentStart(true)
    const { id, total, attendees, discounts } = reservation

    const res = await API.submitReservation(
      accountId,
      dataObj.creditCard,
      {
        id,
        total,
        attendees,
        slots: getFilledSlots(),
        discounts
      },
      true,
      'Unable To Process Payment. Please contact us.'
    )
    if (res.err) {
      setPaymentStart(false)
      return setErrMsg(res.err)
    }

    setSidebarVisible(true)
    setCurrentStep(5)
  }

  const gotoNext = async () => {
    const { id, total, attendees, discounts } = reservation
    const res = await API.submitReservation(
      accountId,
      {},
      {
        id,
        total,
        attendees,
        slots: getFilledSlots(),
        discounts
      },
      false
    )
    if (res.err) {
      return setErrMsg(res.err)
    }
    setSidebarVisible(true)
    setCurrentStep(5)
  }
  useEffect(() => {
    // For scrollIntoView from https://gotgamecamp.com
    window.parent?.postMessage?.('scroll', 'https://gotgamecamp.com')
    setErrMsg('')
  }, [])

  return (
    <>
      <span />
      {acceptPayments
        ? (
          <>
            {paymentType === 'ach' && <ACHForm setErrMsg={setErrMsg} />}
            {paymentType === 'creditCard' && (
              <CreditCardForm
                data={dataObj.creditCard}
                handleChange={handleCreditCardChange}
                setErrMsg={setErrMsg}
              />
            )}
          </>
          )
        : (
          <p>Payment is not accepted</p>
          )}
      <div style={{ color: 'red' }}>{errMsg}</div>
      <div className='form-group' id='pay-now'>
        <div className='row'>
          <div className='col-md-12'>
            <label htmlFor='terms'>PROGRAM TERMS AND CONDITIONS</label>
            <p
              style={{
                minWidth: '100%',
                whiteSpace: 'normal',
                height: '250px',
                overflow: 'auto',
                border: '1px solid black'
              }}
            >
              <p style={{ textAlign: 'center' }}>
                PROGRAM TERMS AND CONDITIONS
              </p>
              Your purchase, enrollment in and use of products, services and
              events (&quot;Programs&quot;) offered by Got Game Sports, LLC
              (&quot;Got Game&quot;), including your access to
              www.gotgamecamp.com (the “Site”), are subject to these terms and
              conditions (“Terms”). If You purchase or enroll in a Program for
              use by another (for example, a parent purchasing for a child)
              these Terms govern both You and other(s) who use any Program you
              purchase (collectively, “You” “you” and “your”). You agree to be
              bound by the Terms, which is an agreement between Got Game and
              You.
              <br />
              <br />
              PLEASE READ THESE TERMS CAREFULLY TO ENSURE THAT YOU UNDERSTAND
              PLEASE READ THESE TERMS CAREFULLY TO ENSURE THAT YOU UNDERSTAND
              EACH PROVISION. THESE TERMS CONTAIN A MANDATORY INDIVIDUAL
              ARBITRATION AND CLASS ACTION/JURY TRIAL WAIVER PROVISION THAT
              REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE
              DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS. IF YOU DO NOT
              AGREE TO THESE TERMS, YOU MUST NOT PARTICIPATE IN THE PROGRAM.
              <br />
              <br />
              Children: Children under the age of 18 are not permitted to
              purchase or enroll in Programs. Children may provide information
              to Got Game and participate in Programs only with consent of a
              parent or guardian.
              <br />
              <br />
              Program Fees: You agree to make full payment for Programs in the
              amount(s) listed in your shopping cart, the order confirmation
              email You will receive, or as communicated by a Got Game
              authorized representative during registration. Standard shipping
              and handling charges apply. Rates may be lower or higher depending
              on the timing of enrollment with respect to your intended Program
              start date. You must complete payment prior to commencing the
              Program unless you are participating in a free Program.
              <br />
              <br />
              Privacy Policy: Our privacy policy may be found at
              https://gotgamecamp.com/privacy-policy-2, the terms of which are
              incorporated in these Terms by reference.
              <br />
              <br />
              Fees and Payment: Where you sign up for paid access to a Program
              through the Site, you must provide your credit card information.
              All Program fees are due in advance. We may charge sales tax where
              required. Tax rates are different from state to state. You are
              responsible for paying all such taxes. By submitting payment, you
              consent to the terms of use and privacy policy of the relevant
              payment processor, and authorize us and the payment processor to
              share any information and payment instructions you provide with
              your payment provider and any other third-party service
              provider(s) solely as required to complete your transactions.
              <br />
              <br />
              No Refunds: We do not offer refunds at this time. If you would
              like to cancel participation in a Program, we will work with you
              to find a Got Game Program that meets your needs and/or offer you
              a credit in the same amount you have paid towards a future Program
              of your choice. You must contact us by phone or email at
              customerservice@gotgamecamp.com at least 30 days before your
              selected Program start date to cancel or change Programs in order
              to receive a credit. Notwithstanding the foregoing, there are no
              refunds or credits for misbehaved campers asked to leave a
              Program.
              <br />
              <br />
              Intellectual Property: The Site is owned by Got Game and its
              licensors. Programs are for your personal and non-commercial use
              only. You may not enroll in or use any Program for the benefit of
              any competitor of Got Game. Programs may not be shared, re-sold,
              reproduced, re-published, modified, transferred or distributed in
              any way without Got Game’s prior written permission. All video,
              audio, text, diagrams, images, animations and other content that
              on the Site, regardless of medium or format, (collectively, “Site
              Content”), are protected by copyright law and belong to Got Game
              and its licensors. You may not download, record, screenshot, copy
              or reproduce the Site in any way. You may not attempt to
              decompile, reverse engineer, scrape or data-mine the Site. The
              trademarks, service marks, designs, and logos displayed on the
              Site are the registered and unregistered trademarks of Got Game,
              Got Game&#39;s licensors. Subject to your compliance with these
              Terms, Got Game grants You a limited, personal, non-exclusive,
              revocable and non-transferable license to access the Site.
              <br />
              <br />
              Third Parties and Links: You may be able to link from the Site to
              third party websites and third party web sites may link to the
              Site (&quot;Linked Sites&quot;). You acknowledge and agree that we
              have no responsibility for the content, products, services,
              advertising or other materials which may be provided by or through
              Linked Sites, even if they are owned or run by affiliates of ours.
              Links to Linked Sites do not constitute an endorsement or
              sponsorship by us. You agree to be bound by the terms and
              conditions and privacy policies of such Linked Sites.
              <br />
              <br />
              Electronic Communications: With the purchase of any program you
              agree that we may send to you electronic notices or other
              communication regarding the Programs and the Site. These types of
              electronic communications will be sent to the email address that
              was provided during registration and/or the email address
              associated with your account. The contents of any communication
              are effective when sent, regardless of when you receive or whether
              you read the communication. You can stop receiving these notices
              and communications at any time upon request. By providing your
              email, you are representing you are of legal age to receive
              electronic communications and may receive electronic
              communications and exclusive offers. These exclusive offers may be
              unavailable depending on your state and local regulations. All
              offers are Void Where Prohibited. The email address provided will
              be used by Got Game in accordance with our Privacy Policy. You can
              choose to stop receiving offers at any time by filling out an
              unsubscribe request, using the unsubscribe option on
              communications or contacting us using the contact information on
              the Site.
              <br />
              <br />
              User Content: If You submit comments, photos and other content to
              us (“User Content”) through Site(s), social media communities,
              survey responses, email or otherwise, You grant us an irrevocable,
              royalty-free, perpetual, transferable, license to use, modify,
              create derivative works from, publish, display and sublicense User
              Content, in whole or in part, in any format and on any platform
              either now known or hereinafter invented, and to associate User
              Content with your name and/or likeness. You are solely responsible
              for your User Content. You represent that You have the right to
              submit User Content to us and the right to grant us the license
              described above. You warrant that User Content, and our use
              thereof, does not and will not infringe any patent, trademark,
              trade secret, copyright, or other intellectual or proprietary or
              privacy right of any party or individual.
              <br />
              <br />
              Copyright Policy: If you are a copyright owner or agent thereof
              and believe that content on the Site infringes upon your
              copyright, please submit notice, pursuant to the Digital
              Millennium Copyright Act (17 U.S.C. § 512 ©) to our Copyright
              Agent with the following information: (I) an electronic or
              physical signature of the person authorized to act on behalf of
              the owner of the copyright; (II) a description of the copyrighted
              work that you claim has been infringed; (III) the URL of the
              location containing the material that you claim is infringing;
              (IV) your address, telephone number, and email address; (V) a
              statement by you that you have a good faith belief that the
              disputed use is not authorized by the copyright owner, its agent,
              or the law; and (VI) a statement by you, made under penalty of
              perjury, that the above information in your Notice is accurate and
              that you are the copyright owner or authorized to act on the
              copyright owner&#39;s behalf.
              <br />
              <br />
              Our Copyright Agent can be reached as follows:
              <br />
              By mail:
              <br />
              Attn: Copyright Agent
              <br />
              Got Game Sports, LLC
              <br />
              PO Box 360553
              <br />
              Los Angeles CA, 90036
              <br />
              By phone: (800) 975-8189
              <br />
              By email: customerservice@gotgamecamp.com
              <br />
              <br />
              Passwords: When You set up a Got Game account, You must choose a
              password. You are solely responsible for maintaining the
              confidentiality of your password and for any and all use of your
              account. You agree not to disclose your password to any third
              party. Got Game has the right to terminate your account for any
              reason at our sole discretion without notice to You.
              <br />
              <br />
              Mobile Devices, Phone Calls and SMS: If You provide Got Game with
              a telephone number, such as when You purchase, enroll in or use a
              Got Game Program, You agree that Got Game may contact You about
              your Program and about other Programs and opportunities by
              telephone and/or text message utilizing automated technology at
              such telephone number(s). You understand that this consent is not
              required to purchase goods or services from Got Game. Programs may
              offer Content and features that are available via a mobile device.
              Standard messaging, data and other fees may be charged by your
              carrier. Your carrier may prohibit or restrict certain mobile
              features and certain mobile features may be incompatible with your
              carrier or mobile device. Contact your carrier with questions
              regarding these issues.
              <br />
              <br />
              Class Schedule and Location Changes: Class schedules and locations
              of Programs are subject to change and/or cancellation. Got Game
              will notify You of any changes or cancellations and provide an
              alternative option.
              <br />
              <br />
              Program Changes: Got Game frequently updates, revises and modifies
              Programs, the Site, features and services (“Program Changes”). Got
              Game reserves the right to make changes to Program Changes at any
              time, subject to such changes not materially degrading the
              Program.
              <br />
              <br />
              Student Code of Conduct: Got Game may remove from Programs
              students whom Got Game deems in its discretion to be disruptive to
              the learning environment, dangerous to other students or faculty,
              have acted in a manner that shows lack of dignity and respect for
              faculty and/or students, violated any intellectual property rights
              of Got Game or others, or have engaged in academic misconduct,
              such as cheating or violating confidentiality. Students removed
              from Programs for violations of the code of conduct are not
              eligible for refunds.
              <br />
              <br />
              Indemnity: You agree to indemnify and hold Got Game, its managers,
              members, officers, employees, agents and affiliates, including,
              without limitation, all third party schools, parks, and other
              locations that partner with Got Game, harmless from any and all
              claims, liabilities, damages, costs and expenses, including actual
              outside attorneys’ fees and court costs, in any way arising from,
              related to or in connection with your participation in the
              Program, your violation of these Terms or the posting or
              transmission of any materials on or through the Program by you,
              including, but not limited to, any third-party claim that any
              information, materials or Content you provide infringes any
              third-party proprietary right. You further agree to reimburse Got
              Game, within thirty (30) days of each demand for reimbursement,
              for any and all costs, liabilities, expenses, fees, fines,
              professional fees and other amounts paid or incurred by Got Game
              (or such other indemnitee) in connection with the foregoing
              indemnity.
              <br />
              <br />
              Disclaimer and Limitation of Liability: PROGRAMS ARE PROVIDED “AS
              IS” AND GOT GAME DISCLAIMS ANY AND ALL EXPRESS OR IMPLIED
              WARRANTIES, INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTIES OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY AND
              NON-INFRINGEMENT. IN ADDITION, GOT GAME MAKES NO REPRESENTATION
              THAT THE OPERATION OF OUR PROGRAM OR SITE WILL BE UNINTERRUPTED OR
              ERROR FREE, AND WE WILL NOT BE LIABLE FOR THE CONSEQUENCES OF ANY
              INTERRUPTIONS OR ERRORS. TO THE MAXIMUM EXTENT ALLOWED UNDER
              APPLICABLE LAW, GOT GAME, ITS PRESENT AND FUTURE OFFICERS,
              DIRECTORS, EMPLOYEES, AGENTS, LICENSORS, SUPPLIERS, AND ANY THIRD
              PARTY INFORMATION PROVIDERS TO THE SITES, SHALL NOT BE LIABLE FOR
              ANY LOSS, INJURY, CLAIM, LIABILITY, OR DAMAGE OF ANY KIND
              RESULTING FROM YOUR PARTICIPATION IN THE PROGRAM. TO THE MAXIMUM
              EXTENT ALLOWED UNDER APPLICABLE LAW, GOT GAME SHALL NOT BE LIABLE
              FOR ANY SPECIAL, DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, OR
              CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER IN ANY WAY DUE TO,
              RESULTING FROM, OR ARISING IN CONNECTION WITH YOUR PARTICIPATION
              IN THE PROGRAM. IN NO EVENT WILL GOT GAME’S TOTAL LIABILITY TO YOU
              EXCEED THE AMOUNTS PAID BY YOU FOR YOUR PROGRAM.
              <br />
              <br />
              Arbitration and Class/Collective Action Waiver: These Terms are
              governed by and construed in accordance with the internal law of
              the State of California without regard to its principles of
              conflicts of laws. Any action arising out of or relating to these
              Terms shall be filed only in the state or federal courts located
              in the County of Los Angeles in the State of California, and you
              hereby consent and submit to the exclusive personal jurisdiction
              of such courts for the purpose of litigating any such action.
              Where a conflict exists between Got Game’s Terms of Use and these
              Terms, the Terms govern. Any dispute, controversy, proceeding, or
              claim arising out of or in connection with or relating to these
              Terms, any of Got Game’s policies, or the Program, in each case,
              whether in contract, tort, common or statutory law, equity or
              otherwise (collectively, a “Dispute”) shall be resolved by binding
              confidential arbitration by JAMS pursuant to its Optional
              Expedited Arbitration Procedures then in effect for JAMS, except
              as provided herein. The arbitration will be conducted in Los
              Angeles County, California, unless you and Got Game agree
              otherwise. Any judgment on the award rendered by the arbitrator
              may be entered in any court of competent jurisdiction. Nothing in
              this section shall be deemed as preventing Got Game from seeking
              injunctive or other equitable relief from the courts as necessary
              to prevent the actual or threatened infringement,
              misappropriation, dilution, or violation of our data security,
              intellectual property rights, or other proprietary rights. YOU AND
              GOT GAME EACH AGREE TO WAIVE ITS RIGHT TO TRIAL BY JURY AND THAT
              ANY PROCEEDINGS TO RESOLVE OR LITIGATE ANY DISPUTE ARISING
              HEREUNDER WILL BE CONDUCTED SOLELY ON AN INDIVIDUAL BASIS, AND
              THAT YOU WILL NOT SEEK TO HAVE ANY DISPUTE HEARD AS A CLASS
              ACTION, A REPRESENTATIVE ACTION, A COLLECTIVE ACTION, A PRIVATE
              ATTORNEY-GENERAL ACTION, OR IN ANY PROCEEDING IN WHICH YOU ACT OR
              PROPOSE TO ACT IN A REPRESENTATIVE CAPACITY. YOU FURTHER AGREE
              THAT NO PROCEEDING WILL BE JOINED, CONSOLIDATED, OR COMBINED WITH
              ANOTHER PROCEEDING WITHOUT THE PRIOR WRITTEN CONSENT OF GOT GAME
              AND ALL PARTIES TO ANY SUCH PROCEEDING. ANY CAUSE OF ACTION OR
              CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OR
              THE SITES MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF
              ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS
              PERMANENTLY BARRED.
              <br />
              <br />
              General: These Terms constitute the entire agreement between you
              and Got Game and govern your use of the Program and Site, and they
              supersede any prior agreements between you and Got Game, provided,
              however, that these Terms shall be read in conjunction with the
              “Terms of Use” and “Privacy Policy,” as applicable. You also may
              be subject to additional terms and conditions that are applicable
              to certain parts of the Program. Got Game may terminate this
              agreement and deny you access to the Program at any time,
              immediately and without notice, if in Got Game’s sole discretion
              you fail to comply with any provision of these Terms. You agree
              that no joint venture, partnership, employment or agency
              relationship exists between Got Game and you as a result of these
              Terms or your use of the Program. Any claim or cause of action you
              may have with respect to Got Game or the Program must be commenced
              within one (1) year after the claim or cause of action arose or be
              forever barred. The failure of Got Game to exercise or enforce any
              right or provision of these Terms shall not constitute a waiver of
              such right or provision. If any provision in these Terms is held
              invalid, the remainder of these Terms shall continue to be
              enforceable. If any provision in these Terms is deemed unlawful,
              void or unenforceable, then that provision is deemed severable
              from these Terms and the remaining provisions are still valid and
              enforceable. You may not assign these Terms nor any of your rights
              or obligations under these Terms without Got Game’s express prior
              written consent. These Terms inure to the benefit of Got Game’s
              successors, assigns, affiliates and licensees. The section titles
              in these Terms are for convenience only and have no legal or
              contractual effect. All notice required or permitted under these
              Terms shall be made in writing by mail or by email to the
              addresses in the Contact section below.
              <br />
              <br />
              Contact: All comments, queries and requests relating to these
              Terms are welcomed and should be addressed as follows:
              <br />
              <br />
              By Mail:
              <br />
              Got Game Sports, LLC
              <br />
              PO Box 360553
              <br />
              Los Angeles CA, 90036
              <br />
              By phone: (800) 975-8189
              <br />
              By email: customerservice@gotgamecamp.com
              <br />
            </p>
          </div>
          <div className='col-md-12'>
            <div className='form-check'>
              <input
                className='form-check-input'
                id='agree'
                type='checkbox'
                value={agreed.toString()}
                onChange={e => {
                  setAgreed(!!e.target.value)
                }}
              />
              <label className='form-check-label' htmlFor='agree'>
                I have read and I accept the PROGRAM TERMS AND CONDITIONS above.
              </label>
            </div>
          </div>
          <div className='col-md-12' style={{ textAlign: 'center' }}>
            <button
              type='button'
              className='btn btn-default btn-lg'
              style={{
                marginRight: '5px',
                marginTop: '10px',
                borderRadius: '5'
              }}
              onClick={() => {
                prev()
              }}
            >
              Back
            </button>
            {acceptPayments
              ? (
                <button
                  type='button'
                  className='btn btn-default btn-lg'
                  style={{
                    marginRight: '5px',
                    marginTop: '10px',
                    borderRadius: '5'
                  }}
                  disabled={paymentStart}
                  id='confirm-purchase'
                  onClick={() => {
                    next()
                  }}
                >
                  {paymentStart
                    ? 'processing payment'
                    : `Checkout (${reservation.total})`}
                </button>
                )
              : (
                <button
                  type='button'
                  className='btn btn-default btn-lg'
                  style={{
                    marginRight: '5px',
                    marginTop: '10px',
                    borderRadius: '5'
                  }}
                  onClick={() => {
                    gotoNext()
                  }}
                >
                  Next
                </button>
                )}
          </div>
        </div>
      </div>
    </>
  )
}

export { Step4 }
