import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux'
import numberOfAttendeesIcon from '../../../../assets/icons/numberOfAttendees.svg'
import { SessionsPerCampers } from '../../../../types/libertyLake'
import calendarIcon from '../../../../assets/icons/calendar.svg'
import pricingIcon from '../../../../assets/icons/dollar.svg'
import discountIcon from '../../../../assets/icons/discount.svg'
import {
  checkIfEarlyPayDiscount,
  checkIfMultiWeekDiscount,
  checkIfOccupationalDiscount,
  checkIfPreSchoolDiscount,
  checkIfSiblingDiscount,
  checkIfTransportationDiscount,
  formatNumberWithCommas
} from '../../../../helpers/LibertyLake/helpers'

const Step4 = () => {
  const { sessionsPerCampers, familyInfo, pricingMap } = useSelector((state: RootState) => state.steps)

  return <>
    <div className='step4'>
      <div className="summaryDetailsArea">
        <h3 className="summaryTitle">Summary</h3>
        <div className="familyInfoCard">
          <h3 className="cardTitle">Guardian's Information</h3>
          <div className="row">
            <label className='fieldLabel'><span>#</span>Name: {familyInfo.firstName} {familyInfo.lastName}</label>
          </div>
          <div className="row">
            <label className='fieldLabel'><span>#</span>Email: {familyInfo.email}</label>
          </div>
          <div className="row">
            <label className='fieldLabel'><span>#</span>Phone: {familyInfo.phone}</label>
          </div>
          {familyInfo.totalFamilyDiscount > 0 && (
            <>
              <div className="row">
                <label className='fieldLabel'><span>#</span>Discounts:</label>
              </div>
              {checkIfPreSchoolDiscount(sessionsPerCampers) && (
                <div className='row subRow'>
                  <label className='fieldLabel'><span>-</span>Pre-School Discount</label>
                </div>
              )}
              {checkIfMultiWeekDiscount(sessionsPerCampers) && (
                <div className='row subRow'>
                  <label className='fieldLabel'><span>-</span>Multi-Week Discount</label>
                </div>
              )}
              {checkIfSiblingDiscount(sessionsPerCampers) && (
                <div className='row subRow'>
                  <label className='fieldLabel'><span>-</span>Sibling Discount</label>
                </div>
              )}
              {checkIfOccupationalDiscount(familyInfo) && (
                <div className='row subRow'>
                  <label className='fieldLabel'><span>-</span>{familyInfo.occupation}</label>
                </div>
              )}
              {checkIfTransportationDiscount(familyInfo) && (
                <div className='row subRow'>
                  <label className='fieldLabel'><span>-</span>Transportation Discount</label>
                </div>
              )}
              {checkIfEarlyPayDiscount(familyInfo) && (
                <div className='row subRow'>
                  <label className='fieldLabel'><span>-</span>Early Bird Discount</label>
                </div>
              )}
            </>
          )}

        </div>
        <div className="numberOfAttendees">
          <img src={numberOfAttendeesIcon} className="icon" />
          <label className='fieldLabel'>{sessionsPerCampers.length} Attendee(s)</label>
        </div>
        <div className="sessionsDetailsArea">
          {sessionsPerCampers.length && sessionsPerCampers.map((sessionsPerAttendee: SessionsPerCampers, i: number) => (
            <div className='attendeeDetailCard' key={`${i}:${sessionsPerAttendee.camperId}`}>
              <h3 className='cardTitle'>{sessionsPerAttendee.camperId}</h3>
              <div className="mainRow">
                <div className="column1">
                  <div className="row">
                    <label className='fieldLabel'><span>#</span>Grade: {sessionsPerAttendee.grade}</label>
                  </div>
                </div>
                <div className="column2">
                  <div className="sessionsWeeksContainer">
                    {sessionsPerAttendee.weeks.length && sessionsPerAttendee.weeks.map((week: string, j: number) => (
                      <div className="eachWeek" key={`${j}:${week}`}>
                        <img src={calendarIcon} className='icon' />
                        <label className='fieldName'>{week}</label>
                        <span>{(pricingMap && pricingMap[week.trim()].date) || ''}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="summaryAmountArea">
        <h3 className="pricingBreakDownTitle">Pricing Breakdown</h3>
        <div className='pricingColumn'>
          <div className="pricingRow">
            <label className='fieldLabel'>Sub Total</label>
            <div className="price">
              <img src={pricingIcon} className='pricingIcon' />
              <h3 className='subPrice'>${formatNumberWithCommas(familyInfo.familySessionsCost)}</h3>
            </div>
          </div>
          <div className="discountRow">
            <label className='fieldLabel'>Discount</label>
            <div className="price">
              <img src={discountIcon} className='discountIcon' />
              <h3 className='subPrice'>- ${formatNumberWithCommas(familyInfo.totalFamilyDiscount)}</h3>
            </div>
          </div>
        </div>
        <div className="grandTotal">
          <h3 className='totalText'>Grand Total</h3>
          <h2 className="grandTotalPrice">${formatNumberWithCommas(familyInfo.grandTotalFamilyCost)}</h2>
        </div>
      </div>
    </div>
  </>
}

export default Step4
