/* eslint-disable no-use-before-define */
import React from 'react'

const ACHForm = ({ selectedProgram, setErrMsg }: any) => {
  return (
    <>
      <div className='row' id='achContainer' style={{ margin: '20px 0' }}>
        <div id='achID'>
          <form>
            <div className='form-row'>
              <div className='form-group col-md-12'>
                <label htmlFor='accountHolder'>Account Holder Name</label>
                <input
                  type='text'
                  className='form-control'
                  id='accountHolder'
                  placeholder='Account Holder Name'
                />
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='routingNumber'>Routing Number</label>
                <input
                  type='email'
                  className='form-control'
                  id='routingNumber'
                  placeholder='Routing Number'
                />
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='accountNumber'>Account Number</label>
                <input
                  type='text'
                  className='form-control'
                  id='accountNumber'
                  placeholder='Account Number'
                />
              </div>
            </div>
            <fieldset className='form-group'>
              <div className='row'>
                <legend className='col-form-label col-sm-2 pt-0'>
                  Account Type
                </legend>
                <div className='col-sm-10'>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='accountType'
                      id='businessChecking'
                      value='checking'
                      checked
                    />
                    <label
                      className='form-check-label'
                      htmlFor='businessChecking'
                    >
                      Checking
                    </label>
                  </div>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='accountType'
                      id='businessSavings'
                      value='savings'
                    />
                    <label
                      className='form-check-label'
                      htmlFor='businessSavings'
                    >
                      Savings
                    </label>
                  </div>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </>
  )
}

export { ACHForm }
