import { useEffect, useState } from 'react'
import arrowRight from '../../../../assets/icons/arrowRight.svg'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../../redux'
import stepSlice from '../../../../redux/slice/steps.slice'
import { FamilyInfo, SessionsPerCampers } from '../../../../types/libertyLake'

const Step3 = () => {
  const dispatch: AppDispatch = useDispatch()
  const { libertyLakeData, sessionsPerCampers, familyInfo, pricingMap, transportationDiscountMap } = useSelector((state: RootState) => state.steps)

  const [amTransportationMeansList, setAmTransportationMeansList] = useState(['Central Express Bus (incl.)', 'Early Drop-off (incl)'])
  const [pmTransportationMeansList, setPmTransportationMeansList] = useState(['Central Express Bus (incl.)', 'Late Pick-up 4:15 PM - 6:00 PM (incl)'])

  const [occupationsList, setOccupationsList] = useState<string[]>([])
  const [occupationsDiscount, setOccupationsDiscount] = useState<any>(null)
  const [occupationDropdown, showOccupationDropdown] = useState(false)
  const [amDropdown, showAmDropdown] = useState(false)
  const [pmDropdown, showPmDropdown] = useState(false)

  useEffect(() => {
    if (libertyLakeData) {
      const OccupationDiscountData = libertyLakeData.Discount.Occupational
      setOccupationsDiscount(OccupationDiscountData)
      setOccupationsList(Object.keys(OccupationDiscountData))
      const transportationData = Object.keys(libertyLakeData.Discount.Transportation)
      transportationData.forEach(td => {
        if (td.includes('Drop-off') && amTransportationMeansList.length === 2) {
          setAmTransportationMeansList(prevArray => [...prevArray, td])
        } else if (td.includes('Pick') && pmTransportationMeansList.length === 2) {
          setPmTransportationMeansList(prevArray => [...prevArray, td])
        }
      })
    }
  }, [libertyLakeData])

  useEffect(() => {
    const familySessionsCost = getAllCampersSessionPrice(sessionsPerCampers)

    // transportation discount
    const flatTransportationDiscount =
      familyInfo.uniqueSessions.length * familyInfo.amTransportationDiscount + familyInfo.uniqueSessions.length * familyInfo.pmTransportationDiscount
    const amountAfterTransportationDiscount = familyInfo.familyPrice - flatTransportationDiscount

    // occupational discount
    const amountAfterOccupationalDiscount = amountAfterTransportationDiscount * (1 - familyInfo.occupationalDiscountPercentage)

    // early bird discount
    let earlyPayDiscount = 0
    sessionsPerCampers.forEach(sessionsPerCamper => {
      earlyPayDiscount += familyInfo.isEarlyPaying ? sessionsPerCamper.weeks.length * 25 : 0
    })

    const grandTotalPrice = amountAfterOccupationalDiscount - earlyPayDiscount

    const updatedFamilyDetails: FamilyInfo = {
      ...familyInfo,
      familySessionsCost,
      totalFamilyDiscount: familySessionsCost - grandTotalPrice,
      grandTotalFamilyCost: grandTotalPrice
    }

    dispatch(stepSlice.actions.setFamilyInfo(updatedFamilyDetails))
  }, [
    familyInfo.occupation,
    familyInfo.isEarlyPaying,
    familyInfo.amTransportationDiscount,
    familyInfo.pmTransportationDiscount
  ])

  const getAllCampersSessionPrice = (sessionsPerCampers: SessionsPerCampers[]): number => {
    let sum = 0
    sessionsPerCampers.forEach(sessionForCamper => {
      sessionForCamper.weeks.forEach(week => {
        sum += (pricingMap && pricingMap[week].price) || 0
      })
    })
    return sum
  }

  const getOccupationalDiscountPercentage = (occupation: string) => {
    return occupationsDiscount[occupation].Percentage
  }

  const getTransportationDiscountAmount = (transportationMean: string) => {
    if (transportationDiscountMap && transportationDiscountMap[transportationMean]) {
      return transportationDiscountMap[transportationMean].Amount
    }
    return 0
  }

  return <>
    <div className='step3'>
      <div className="formArea">
        <label className='formTitle'>Calculate Discount</label>
        <h4 className='transportationMeans'>What are your transportation preferences?</h4>
        <div className='amPmRow'>
          <div className="inputFieldArea">
            <label className='fieldLabel'>AM Choice</label>
            <div
              className="selectInputFieldArea"
              onClick={() => {
                showAmDropdown(true)
              }}
            >
              {familyInfo.amTransportationChoice.length ? familyInfo.amTransportationChoice : 'Select...'}
              <img className='arrowIcon' src={arrowRight} />
            </div>
            <label className='helpText'>* Get $25/week discount on dropping off your kids to Camp</label>
            {amDropdown
              ? (
                <div className="dropdownContainer">
                  {amTransportationMeansList.map((transportationMean: string, index: number) => (
                    <div
                      className='dropdownOption'
                      key={`${transportationMean}:${index}`}
                      onClick={() => {
                        const updatedInfo: FamilyInfo = {
                          ...familyInfo,
                          amTransportationChoice: transportationMean,
                          amTransportationDiscount: getTransportationDiscountAmount(transportationMean)
                        }
                        dispatch(stepSlice.actions.setFamilyInfo(updatedInfo))
                        showAmDropdown(false)
                      }}
                    >
                      {transportationMean}
                    </div>
                  ))}
                </div>
              )
              : ''}
          </div>
          <div className="inputFieldArea">
            <label className='fieldLabel'>PM Choice</label>
            <div
              className="selectInputFieldArea"
              onClick={() => {
                showPmDropdown(true)
              }}
            >
              {familyInfo.pmTransportationChoice.length ? familyInfo.pmTransportationChoice : 'Select...'}
              <img className='arrowIcon' src={arrowRight} />
            </div>
            <label className='helpText'>* Get $25/week discount on picking up your kids from Camp</label>
            {pmDropdown
              ? (
                <div className="dropdownContainer">
                  {pmTransportationMeansList.map((transportationMean: string, index: number) => (
                    <div
                      className='dropdownOption'
                      key={`${transportationMean}:${index}`}
                      onClick={() => {
                        const updatedInfo: FamilyInfo = {
                          ...familyInfo,
                          pmTransportationChoice: transportationMean,
                          pmTransportationDiscount: getTransportationDiscountAmount(transportationMean)
                        }
                        dispatch(stepSlice.actions.setFamilyInfo(updatedInfo))
                        showPmDropdown(false)
                      }}
                    >
                      {transportationMean}
                    </div>
                  ))}
                </div>
              )
              : ''}
          </div>
        </div>

        <div className="inputFieldArea">
          <label className='fieldLabel'>Are you active military or one of the following occupation?</label>
          <div
            className="selectInputFieldArea"
            onClick={() => showOccupationDropdown(true)}
          >
            {familyInfo.occupation.length > 0 ? familyInfo.occupation : 'Select...'}
            <img className='arrowIcon' src={arrowRight} />
          </div>
          <label className='helpText'>
            * {familyInfo.occupationalDiscountPercentage}% discount will be applied for your occupation: {familyInfo.occupation}
          </label>
          {occupationDropdown
            ? (
              <div className="dropdownContainer">
                {occupationsList.map((occupation: string, index: number) => (
                  <div
                    className='dropdownOption'
                    key={`${occupation}:${index}`}
                    onClick={() => {
                      const updatedInfo: FamilyInfo = {
                        ...familyInfo,
                        occupation,
                        occupationalDiscountPercentage: getOccupationalDiscountPercentage(occupation)
                      }
                      dispatch(stepSlice.actions.setFamilyInfo(updatedInfo))
                      showOccupationDropdown(false)
                    }}
                  >
                    {occupation}
                  </div>
                ))}
              </div>
            )
            : ''}
        </div>
        <div className='earlyPayDiscountArea'>
          <label className='fieldLabel'>Will you pay in full by Sept 30 for an additional discount?</label>
          <div className='checkboxArea'>
            <input type='checkbox' checked={familyInfo.isEarlyPaying} className='checkbox' onChange={() => { }} />
            <label
              className='checkmark'
              onClick={() => {
                const updatedInfo: FamilyInfo = {
                  ...familyInfo,
                  isEarlyPaying: !familyInfo.isEarlyPaying,
                  earlyPayDiscount: !familyInfo.isEarlyPaying ? 25 * familyInfo.uniqueSessions.length : 0
                }
                dispatch(stepSlice.actions.setFamilyInfo(updatedInfo))
              }}
            />
          </div>
        </div>
        <label className='helpText'>* $25 off per week per camper</label>
      </div>
    </div>
    {(occupationDropdown || amDropdown || pmDropdown) && <div className='backDrop' onClick={() => {
      showOccupationDropdown(false)
      showAmDropdown(false)
      showPmDropdown(false)
    }} />}
  </>
}

export default Step3
