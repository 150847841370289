import axios from 'axios'

import { config } from '../config'

import * as Sentry from '@sentry/react'

const API = {
  getSettings: async (accountId: string) => {
    try {
      const res = await axios.get(`${config.apiUrl}/public/settings`, {
        headers: {
          accountid: accountId
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  getCategories: async (accountId: string) => {
    try {
      const res = await axios.get(`${config.apiUrl}/public/programs`, {
        headers: {
          accountid: accountId
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  getUUID: async (accountId?: string) => {
    try {
      const res = await axios.get(`${config.apiUrl}/public/uuid`, {
        headers: {
          accountid: accountId
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  updateProgress: async (
    accountId: string,
    progress: any,
    reservation: any
  ) => {
    try {
      const res = await axios.post(
        `${config.apiUrl}/public/reservations/update-progress`,
        { progress, reservation },
        {
          headers: {
            accountid: accountId
          }
        }
      )
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  applyDiscount: async (accountId: string, options: any, controller: AbortController) => {
    try {
      const res = await axios.post(
        `${config.apiUrl}/public/reservations/apply-discount`,
        options,
        {
          headers: {
            accountid: accountId
          },
          signal: controller.signal
        }
      )
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  submitReservation: async (
    accountId: string,
    paymentObj: any,
    reservation: any,
    processPayment: any,
    defaultErrMsg?: string
  ) => {
    try {
      const cardDetails = processPayment
        ? {
          name: paymentObj.name,
          cvv: paymentObj.cvv,
          num: paymentObj.num,
          expMon: paymentObj.expMon,
          expYear: paymentObj.expYear,
          zip: paymentObj.zip
        }
        : {}
      const res = await axios.post(
        config.lambdaUrls.createReservation,
        {
          ...cardDetails,
          total: parseFloat(reservation.total),
          reservation,
          processPayment
        },
        {
          headers: {
            accountid: accountId,
            'Cache-Control': 'no-cache'
          }
        }
      )
      return formatResponse(res, null, defaultErrMsg)
    } catch (err: any) {
      console.error(err)
      logErrorToSentry(err, reservation, err?.response)
      logErrorToGoogleSheet(err, reservation, accountId, paymentObj)
      return formatResponse(null, err, defaultErrMsg)
    }
  }
}

interface responseProps {
  data: undefined | any
  err: undefined | string
}

// format response will return a response object {data: {} or [], err: string}
const formatResponse = (res: any, err?: any, defaultErrStr?: string) => {
  const formattedRes: responseProps = {
    data: undefined,
    err: undefined
  }
  if (res && res.data) {
    formattedRes.data = res.data
  } else if (
    err &&
    err.response &&
    err.response.data &&
    err.response.data.msg
  ) {
    formattedRes.err = `${err.response.data.msg}`
  } else if (defaultErrStr) {
    formattedRes.err = defaultErrStr
  } else {
    formattedRes.err = 'Unable to fetch from API'
  }
  return formattedRes
}

const logErrorToSentry = (err: any, reservation: any, errResp: any, paymentObj?: any) => {
  try {
    Sentry.captureException(err, {
      user: paymentObj?.name,
      contexts: {
        reservation,
        errResponse: errResp
      }
    })
  } catch (err) {
    console.error(err)
  }
}

const logErrorToGoogleSheet = async (err: any, data: any, accountId: string, paymentObj: any) => {
  try {
    await axios.post(
      `${config.apiUrl}/public/reservations/transaction-errors`,
      {
        error: err ? JSON.stringify(err, null, 2) : '',
        // Name on CC + axios resp
        errorMessage: `${paymentObj?.name} ${JSON.stringify({
          msg: err?.response?.data?.msg,
          status: err?.response?.status,
          statusText: err?.response?.statusText,
          headers: err?.response?.headers
        })}`,
        errorDescription: err?.cause ?? err?.toString(),
        reservation: data
      },
      {
        headers: {
          accountid: accountId
        }
      }
    )
  } catch (err) {
    console.error(err)
  }
}

export { API }
