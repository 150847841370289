const ArrowRightIcon = ({ isCurrentStep }: { isCurrentStep: boolean }) => {
  return <div className='arrowIcon'>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.713 12L9.70196 5.98999L8.28796 7.40399L12.888 12.004L8.28796 16.597L9.70196 18.011L15.713 12Z"
        fill={isCurrentStep ? '#3E3D40' : '#ADAEB6'} />
    </svg>
  </div>
}

export default ArrowRightIcon
