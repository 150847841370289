/* eslint-disable no-undef */
import { useEffect, useState, useReducer, useRef } from 'react'

import {
  ReservationContext,
  ReservationReducer,
  InitialReservation
} from '../reducers/ReservationReducer'
import { API } from '../api/API'
import { Sidebar } from '../components/GotGame/Sidebar/Sidebar'
import { Progressbar } from '../components/GotGame/Progressbar/Progressbar'
import { Step1 } from '../components/GotGame/Steps/Step1'
import { Step2 } from '../components/GotGame/Steps/Step2'
import { Step3 } from '../components/GotGame/Steps/Step3'
import { Step4 } from '../components/GotGame/Steps/Step4'
import { Step5 } from '../components/GotGame/Steps/Step5'

import './GotGameTemplate.scss'
import { useQueryParams } from '../hooks/useQueryParams'

interface settingType {
  companyLogo?: string
  acceptPayments?: any
}

interface Option {
  id: string
  title: string
}

interface Session {
  id: string
  title: string
  startTime: string
  endTime: string
  options: Array<Option>
}

const GotGameTemplate = () => {
  const query = useQueryParams()
  const accountId = query.get('aId') ?? ''
  const [reservation, dispatchToReservation] = useReducer(ReservationReducer, {
    ...InitialReservation
  })
  const [currentStep, setCurrentStep] = useState(1)
  const [categories, setCategories] = useState<Array<any>>([])
  const [allRelations] = useState({})
  const [sidebarVisible, setSidebarVisible] = useState(false)
  const [isComplete, setComplete] = useState(false)
  const [resetBar, setResetBar] = useState(false)
  const [loader, setLoader] = useState(true)
  const [loaderRefresh, setLoaderRefresh] = useState(false)
  const [, setErrMsg] = useState('')

  const [settings, setSettings] = useState<settingType>({})

  let height = 0

  const init = async () => {
    const res: any = await API.getUUID(accountId)
    if (res.err) {
      return setErrMsg(res.err)
    }
    dispatchToReservation({ type: 'INIT', id: res?.data?.uuid || '' })
  }

  const getFilledSlots = () => {
    const { form, attendees, slots = [] } = reservation

    // convert form into slot attendees and guardians
    const slotAttendees: Array<any> = []
    const slotGuardians: Array<any> = []
    for (let i = 1; i <= attendees; i += 1) {
      if (form[`attendee_${i}`]) {
        slotAttendees.push(form[`attendee_${i}`])
      }
    }
    if (
      form &&
      form.g1 &&
      (form.g1.firstName || form.g1.lastName || form.g1.email)
    ) {
      slotGuardians.push(form.g1)
    }
    if (
      form &&
      form.g2 &&
      (form.g2.firstName || form.g2.lastName || form.g2.email)
    ) {
      slotGuardians.push(form.g2)
    }

    const slotData = slots.map((slot: any) => {
      const program = reservation.selectedProgram
      const session = getSession(program, slot.sessionId)
      const option = session && getOption(session, slot.optionId)

      return {
        program: {
          id: program.id,
          title: program.title
        },
        session: {
          id: session?.id,
          title: session?.title,
          startTime: session?.startTime,
          endTime: session?.endTime
        },
        option: {
          id: option?.id,
          title: option?.title
        },
        startTime: slot.startTime,
        endTime: slot.endTime,
        locationId: slot.locationId,
        emergencyContactPhone: form.emergencyPhone,
        emergencyContactName: form.emergencyName,
        attendees: slotAttendees,
        guardians: slotGuardians
      }
    })
    return slotData
  }

  const getSession = (
    program: {
      sessions: Array<Session>
    },
    sessionId: string
  ) => {
    return program.sessions.find(s => s.id === sessionId)
  }

  const getOption = (session: Session, optionId: string) => {
    return session.options.find(o => o.id === optionId)
  }

  const updateProgress = async () => {
    const res = await API.updateProgress(accountId, `step${currentStep}`, {
      id: reservation.id,
      slots: getFilledSlots(),
      form: reservation ? reservation.form : undefined,
      discounts: reservation ? reservation.discounts : undefined
    })
    if (res.err) {
      console.log('UPDATE_PROGRESS', res.err)
    }
  }

  const callCountRef = useRef(0)
  const controllerRef = useRef(new AbortController())

  const applyDiscount = async (codeDiscountId: string) => {
    if (reservation.selectedProgram.discounts) {
      controllerRef.current.abort()
      controllerRef.current = new AbortController()

      callCountRef.current += 1
      const callCount = callCountRef.current

      let discountIds = reservation.selectedProgram.discounts
        .filter((d: any) => d.type !== 'fixed' && d.type !== 'percentage')
        .map((d: any) => d.id)
      if (codeDiscountId) {
        discountIds = [...discountIds, codeDiscountId]
      }

      const res = await API.applyDiscount(accountId, {
        programId: reservation.selectedProgram.id,
        discountIds,
        slots: reservation.slots,
        attendees: reservation.attendees
      }, controllerRef.current)

      if (res.err) {
        console.log('Update discount', res.err)
      } else {
        if (callCount === callCountRef.current) {
          dispatchToReservation({ type: 'UPDATE_DISCOUNT', data: res.data })
        }
      }
    } else {
      dispatchToReservation({ type: 'UPDATE_TOTAL' })
    }
  }

  const getCategories = async () => {
    const res = await API.getCategories(accountId)
    if (res.err) {
      return setErrMsg(res.err)
    }
    setCategories(res.data || [])
    setLoader(false)
  }

  const getSettings = async () => {
    const res: any = await API.getSettings(accountId)
    if (res.err) {
      return setErrMsg(res.err)
    }
    setSettings(res.data?.accountSettings)
  }
  useEffect(() => {
    if (accountId) {
      init()
      getCategories()
      getSettings()
      // getAllRelations()
    }
  }, [accountId])

  useEffect(() => {
    if (currentStep > 1) {
      updateProgress()
    }
    if (currentStep === 5) {
      setComplete(true)
    }
    if (
      height === 0 ||
      height !== document.getElementById('page-region')?.offsetHeight
    ) {
      height = document.getElementById('page-region')?.offsetHeight || 0
      window.parent.postMessage({ command: 'setIFrameHeight', height }, '*')
    }
  }, [currentStep])
  useEffect(() => {
    if (loader) {
      const timer = setTimeout(() => setLoaderRefresh(true), 2500)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [loader])
  useEffect(() => {
    applyDiscount('')
  }, [reservation.slots])

  return (
    <ReservationContext.Provider value={[reservation, dispatchToReservation]}>
      <div id='page-region'>
        <div className='layout'>
          <div className='wrapper'>
            {loader && (
              <div className='booking expanded-desktop loader'>
                <div className='loading-spinner'>
                  <div className='div1' />
                  <div className='div2' />
                  <div className='div3' />
                  <div className='div4' />
                </div>
                {loaderRefresh && (
                  <div
                    className='refresh'
                    style={{
                      cursor: 'pointer',
                      alignSelf: 'center',
                      position: 'absolute',
                      paddingTop: '20px'
                    }}
                    onClick={() => {
                      window.location.reload()
                    }}
                  >
                    <span>Click here to refresh the page...</span>
                  </div>
                )}
              </div>
            )}

            <div
              className='booking expanded-desktop'
              style={{ opacity: loader ? '0.1' : '1' }}
            >
              {sidebarVisible && (
                <Sidebar
                  accountId={accountId}
                  isComplete={isComplete}
                  applyDiscount={applyDiscount}
                  companyLogo={settings.companyLogo}
                />
              )}
              <div
                className={`inner-frame ${sidebarVisible ? 'col-md-8' : 'col-md-12'
                  }`}
              >
                <Progressbar
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  setSidebarVisible={setSidebarVisible}
                  isComplete={isComplete}
                  resetBar={resetBar}
                  setResetBar={setResetBar}
                />
                {currentStep === 1 && (
                  <Step1
                    accountId={accountId}
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    categories={categories}
                    resetBar={resetBar}
                    setResetBar={setResetBar}
                  />
                )}
                {currentStep === 2 && (
                  <Step2
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    categories={categories}
                    setSidebarVisible={setSidebarVisible}
                  />
                )}
                {currentStep === 3 && (
                  <Step3
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    categories={categories}
                    allRelations={allRelations}
                    setSidebarVisible={setSidebarVisible}
                  />
                )}
                {currentStep === 4 && (
                  <Step4
                    acceptPayments={settings?.acceptPayments}
                    accountId={accountId}
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    categories={categories}
                    setSidebarVisible={setSidebarVisible}
                    getFilledSlots={getFilledSlots}
                  />
                )}
                {currentStep === 5 && (
                  <Step5
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    categories={categories}
                    setSidebarVisible={setSidebarVisible}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ReservationContext.Provider>
  )
}

export { GotGameTemplate }
