// this is to make sure we are not missing any variables from .env

if (!process.env.REACT_APP_ENV) {
  throw new Error('REACT_APP_ENV required from env')
}
if (!process.env.REACT_APP_API_URL) {
  throw new Error('REACT_APP_API_URL required from env')
}
if (!process.env.REACT_APP_CREATE_RESERVATION_LAMBDA_URL) {
  throw new Error('REACT_APP_CREATE_RESERVATION_LAMBDA_URL required from env')
}
if (!process.env.REACT_APP_LIBERTY_LAKE_SPREADSHEET_URL) {
  throw new Error('REACT_APP_LIBERTY_LAKE_SPREADSHEET_URL required from env')
}
if (!process.env.REACT_APP_LIBERTY_LAKE_API_KEY) {
  throw new Error('REACT_APP_LIBERTY_LAKE_API_KEY required from env')
}
if (!process.env.REACT_APP_LIBERTY_LAKE_WORKFLOW_URL) {
  throw new Error('REACT_APP_LIBERTY_LAKE_WORKFLOW_URL required from env')
}
if (!process.env.REACT_APP_CLIENT_URL) {
  throw new Error('REACT_APP_CLIENT_URL required from env')
}
if (!process.env.REACT_APP_ALERTS) {
  throw new Error('REACT_APP_ALERTS required from env')
}

export const config = {
  version:
    process.env.REACT_APP_VERSION || process.env.npm_package_version || '',
  env: process.env.REACT_APP_ENV,
  apiUrl: process.env.REACT_APP_API_URL,
  clientUrl: process.env.REACT_APP_CLIENT_URL,
  alerts: Boolean(process.env.REACT_APP_ALERTS === 'true'),
  lambdaUrls: {
    createReservation: process.env.REACT_APP_CREATE_RESERVATION_LAMBDA_URL
  },
  libertyLakeSpreadsheetUrl: process.env.REACT_APP_LIBERTY_LAKE_SPREADSHEET_URL,
  libertyLakeApiKey: process.env.REACT_APP_LIBERTY_LAKE_API_KEY,
  libertyLakeWorkflowURL: process.env.REACT_APP_LIBERTY_LAKE_WORKFLOW_URL
}
