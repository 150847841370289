/* eslint-disable no-use-before-define */
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faListOl,
  faProjectDiagram,
  faCalendarAlt,
  faTag
} from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

const Mobile = ({
  accountId,
  isComplete,
  addPromoCode,
  reservation,
  promoCode,
  setPromoCode,
  promoCodeErrMsg,
  companyLogo
}: any) => {
  return (
    <>
      <div className='container' id='sidebar_mobile'>
        <div className='row' style={{ flexWrap: 'nowrap' }}>
          <div className='col-3 text-center d-flex'>
            <img
              className='mx-auto my-auto'
              src={companyLogo}
              style={{ maxWidth: '100%', height: 'auto', maxHeight: '66px' }}
            />
          </div>
          <div className='verticalLine' style={{ width: '1px' }} />
          <div className='col-8' style={{ fontSize: '12px' }}>
            <div>
              <span className='icon-stack'>
                <FontAwesomeIcon
                  icon={faListOl as IconProp}
                  style={{
                    marginTop: '7px',
                    marginRight: '10px',
                    color: '#2f6f64'
                  }}
                />
                Number of Attendee(s): {reservation.attendees}
              </span>
            </div>
            <div>
              <span className='icon-stack'>
                <FontAwesomeIcon
                  icon={faProjectDiagram as IconProp}
                  style={{
                    marginTop: '7px',
                    marginRight: '9px',
                    color: '#00BCD4'
                  }}
                />
                {reservation &&
                reservation.selectedProgram &&
                reservation.selectedProgram.title
                  ? reservation.selectedProgram.title
                  : ''}
              </span>
            </div>
          </div>
        </div>
        <hr />
        <div>
          <span className='icon-stack'>
            <FontAwesomeIcon
              icon={faCalendarAlt as IconProp}
              style={{
                marginTop: '7px',
                marginRight: '5px',
                color: '#FF5722'
              }}
            />
            Sessions
          </span>
        </div>
        <div className='row'>
          {reservation &&
            reservation.slots &&
            reservation.slots.map((s: any, i: number) => {
              return (
                <div
                  key={i}
                  className='row col-12'
                  style={{ fontSize: '12px' }}
                >
                  <div className='col-6'>
                    <div>{s.session.title}</div>
                  </div>
                  <span className='col-6'>
                    ${s.pricePerAttendee} x {reservation.attendees} = $
                    {s.totalPrice}
                  </span>
                </div>
              )
            })}
        </div>
        {reservation &&
          reservation.discounts &&
          reservation.discounts.map((d: any, i: number) => {
            let discountStr = ''
            // custom ALL SUMMER discount for GG
            if (d.title === 'All Weeks' || d.title === 'Three Weeks') {
              discountStr = `-$${d.amount} x ${
                reservation.slots.filter(
                  (s: any) => s.option && s.option.title.includes('Full Week')
                ).length
              } for Full Week`
            } else if (d.title === 'GOTGAMEFAM') {
              discountStr = `-$${d.amount} per week`
            } else if (d.type === 'fixed') {
              discountStr = `-${Number(d.amount).toFixed(2)}`
            } else if (d.type === 'percentage') {
              discountStr = `-${d.amount}%`
            } else if (d.type === 'sibling_percentage') {
              discountStr = `-${d.amount}% after 1st`
            } else if (d.type === 'multi_percentage') {
              discountStr = `-${d.amount}% after 2nd`
            } else if (d.type === 'multi_fixed') {
              discountStr = `-$${d.amount} x ${
                reservation.slots.length * reservation.attendees
              }`
            }
            return (
              <span key={i}>
                <hr />
                <div>
                  <div className='row md-12'>
                    <div className='col-8' style={{ fontSize: '0.8rem' }}>
                      <span className='icon-stack'>
                        <FontAwesomeIcon
                          icon={faTag as IconProp}
                          style={{
                            marginTop: '7px',
                            marginRight: '10px',
                            color: '#673ab7'
                          }}
                        />
                        Discount({d.description}):
                      </span>
                    </div>
                    <div className='col-4' style={{ fontSize: '0.8rem' }}>
                      {discountStr}
                    </div>
                  </div>
                </div>
              </span>
            )
          })}
        <hr />
        {reservation &&
          !isComplete &&
          reservation.selectedProgram &&
          reservation.selectedProgram.discounts &&
          reservation.selectedProgram.discounts.length > 0 && (
            <div className='row md-12'>
              <div className='col-5'>Promo Code:</div>
              <div className='col-4'>
                <input
                  className='input-sm'
                  style={{ width: '100%', border: '1px solid #000' }}
                  value={promoCode}
                  onChange={e => {
                    setPromoCode(e.target.value)
                  }}
                />
              </div>
              <div className='col-3'>
                <button
                  className='btn btn-podsoft btn-sm'
                  onClick={() => {
                    addPromoCode()
                  }}
                >
                  Add
                </button>
              </div>
              <div style={{ color: 'red' }}>{promoCodeErrMsg}</div>
            </div>
        )}
        <br />
        <div className='row col-12'>
          <div className='col-8'>Total:</div>
          <div className='col-4'>${Number(reservation.total).toFixed(2)}</div>
        </div>
      </div>
    </>
  )
}

export { Mobile }
