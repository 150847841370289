import { createContext } from 'react'
import {
  INITIAL_RESERVATION,
  INITIAL_SLOT,
  INITIAL_ATTENDEE
} from '../const/reservations'

const ReservationContext = createContext({})

// at the moment, only allow one slot to be used
const InitialReservation = INITIAL_RESERVATION

interface sessionOptions {
  price?: number
  startTime?: number
  endTime?: number
  action?: string
}

const calculateTotal = (slots = []) => {
  // this is assuming that we only have 1 program
  let total = 0

  // sum up sessions and options in table
  slots.forEach((s: any) => {
    total += Number(s.totalPrice)
  })

  return Number(total)
}

const upsertSession = (
  selectedProgram: any,
  attendees: any,
  slots: any,
  sessionId: any,
  session: sessionOptions = {},
  optionId: any,
  option: sessionOptions = {}
) => {
  let copySlot = { ...INITIAL_SLOT }
  let copyIndex = null
  // if sessionId exists, update it
  for (let i = 0; i < slots.length; i += 1) {
    if (slots[i].sessionId === sessionId) {
      copySlot = { ...slots[i] }
      copyIndex = i
      break
    }
  }

  copySlot.programId = selectedProgram.id
  copySlot.program = selectedProgram
  copySlot.sessionId = sessionId
  copySlot.session = session
  copySlot.optionId = optionId
  copySlot.option = option
  // by default the strategy to figure out price is overwrite if there's an
  // option. If adjustPrice is selected and options exists, and the options
  // price will be added to the session price
  copySlot.pricePerAttendee =
    option && option.price ? option.price : Number(session.price || 0)
  if (option && option.action === 'adjustPrice' && option.price) {
    copySlot.pricePerAttendee =
      Number(session.price || 0) + Number(option.price)
  }

  copySlot.startTime = (option && option.startTime) || session.startTime || 0
  copySlot.endTime = (option && option.endTime) || session.endTime || 0
  copySlot.totalPrice = Number(copySlot.pricePerAttendee) * Number(attendees)

  if (copyIndex !== null) {
    slots[copyIndex] = copySlot
  } else {
    slots.push(copySlot)
  }

  return slots
}

const ReservationReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'INIT':
      state.id = action.id
      break
    // step 1
    case 'SELECT_PROGRAM':
      // Reset slots and discounts since program has changed
      state.slots = []
      state.discounts = []

      // any slots not filled with a programId, fill it
      state.slots = state.slots.map((s: any) => {
        if (!s.programId) {
          s.programId = action.programId
          s.program = action.program
        }
        return s
      })
      // since we only allow one program to be selected, we'll wipe any other slots with different programIds
      state.slots = state.slots.filter((s: any) => {
        return s.programId === action.programId
      })
      state.selectedProgram = action.program
      break
    case 'UPDATE_ATTENDEES':
      state.attendees = action.attendees

      // Reset slots and discounts since attendees have changed
      state.slots = []
      state.discounts = []

      for (let i = 1; i <= state.attendees; i += 1) {
        state.form[`attendee_${i}`] = { ...INITIAL_ATTENDEE }
      }
      break
    // step 2
    case 'UPSERT_SESSION':
      state.slots = upsertSession(
        state.selectedProgram,
        state.attendees,
        state.slots,
        action.sessionId,
        action.session,
        action.optionId,
        action.option
      )
      return { ...state, slots: [...state.slots] }
    case 'REMOVE_SESSION':
      state.slots = state.slots.filter((s: any) => {
        return !(
          s.programId === action.programId && s.sessionId === action.sessionId
        )
      })
      return { ...state, slots: [...state.slots] }
    // case for updating discounts on price through backend
    case 'UPDATE_DISCOUNT':
      state.discounts = action.data.data.discounts
      state.total = Number(action.data.data.total)
      return { ...state }
    // update total if no discount applied
    case 'UPDATE_TOTAL':
      state.total = calculateTotal(state.slots)
      return { ...state }
    // step 3
    case 'UPDATE_FORM':
      if (action.key === 'form') {
        state.form[action.field] = action.value
      } else if (state.form[action.key]) {
        state.form[action.key][action.field] = action.value
      }
      return { ...state, form: { ...state.form } }

    case 'ADD_DISCOUNT':
      return { ...state, discounts: [...state.discounts] }

    default:
      return state
  }
  return state
}

export { ReservationContext, ReservationReducer, InitialReservation }
