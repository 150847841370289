/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import '@fortawesome/fontawesome-free/js/all'
import '@fortawesome/fontawesome-free/css/all.min.css'
import App from './App'
import './styles/index.scss'
import { Provider } from 'react-redux'

import { config } from './config'
import { store } from './redux'
import { CaptureConsole } from '@sentry/integrations'

if (config.alerts) {
  Sentry.init({
    dsn: 'https://c3b520adc5724cfca9c9827551c07bf0@o457843.ingest.sentry.io/5454449',
    release: 'podsoft@' + config.version,
    environment: config.env,
    integrations: [
      new Integrations.BrowserTracing(),
      new CaptureConsole({
        levels: ['warn', 'error']
      })
    ],
    tracesSampleRate: 1.0
  })
}

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
)
