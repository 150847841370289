const INITIAL_SLOT = {
  programId: undefined,
  program: {},
  sessionId: undefined,
  session: {},
  optionId: undefined,
  option: {},
  pricePerAttendee: 0,
  totalPrice: 0,
  startTime: 0,
  endTime: 0,
  // used later
  attendees: [],
  guardians: [],
  locationId: undefined,
  emergencyContactName: undefined,
  emergencyContactPhone: undefined
}

const INITIAL_ATTENDEE = {
  // leave blank for backend to match
  clientId: undefined,
  firstName: '',
  lastName: '',
  email: '',
  dob: '',
  schoolName: '',
  homeroomTeacher: '',
  grade: '',
  notes: ''
}

// used as an attendee
const INITIAL_DELEGATE = {
  // leave blank for backend to match
  clientId: undefined,
  firstName: '',
  lastName: '',
  email: '',
  receiveEmailNotifications: true,
  dob: '',
  schoolName: '',
  highSchoolGradYear: '2021',
  expLevel: 'never',
  friend: ''
}

const INITIAL_GUARDIAN = {
  // leave blank to match
  clientId: undefined,
  prefix: '',
  firstName: '',
  lastName: '',
  email: '',
  receiveEmailNotifications: true,
  phone: '',
  secondaryPhone: '',
  dob: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  attendee1IsGuardians: 'attendee',
  attendee2IsGuardians: 'attendee',
  attendee3IsGuardians: 'attendee',
  guardianIsAttendee1s: 'guardian',
  guardianIsAttendee2s: 'guardian',
  guardianIsAttendee3s: 'guardian'
}

const INITIAL_SERVICE = {
  serviceId: '',
  client: {
    clientId: ''
  },
  startTime: 0,
  endTime: 0,
  locationId: undefined
}

const INITIAL_DISCOUNT = { discountId: undefined }

const INITIAL_FORM = {
  g1: { ...INITIAL_GUARDIAN },
  g2: { ...INITIAL_GUARDIAN },
  emergencyName: '',
  emergencyPhone: ''
}

const INITIAL_RESERVATION = {
  id: undefined,
  slots: [],
  services: [],
  userId: undefined,
  discounts: [],
  total: 0,
  // used for current gg flow
  selectedProgram: {},
  attendees: 0,
  form: { ...INITIAL_FORM },
  // mun
  expLevel: ''
}

export {
  INITIAL_RESERVATION,
  INITIAL_SLOT,
  INITIAL_ATTENDEE,
  INITIAL_GUARDIAN,
  INITIAL_SERVICE,
  INITIAL_DISCOUNT,
  INITIAL_FORM,
  INITIAL_DELEGATE
}
