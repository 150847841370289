import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../redux'
import stepSlice from '../../../redux/slice/steps.slice'
import { SessionsPerCampers } from '../../../types/libertyLake'
import appSlice from '../../../redux/slice/app.slice'
import axios from 'axios'
import { config } from '../../../config'

interface EachCamperSessions {
  allSessions: string[]
  grade: string
}

const Footer = () => {
  const dispatch = useDispatch()
  const { step, campers, sessionsPerCampers, familyInfo } = useSelector((state: RootState) => state.steps)

  const isStepOneValid = (): boolean => {
    if (
      campers.length > 0 &&
      familyInfo.firstName.length &&
      familyInfo.lastName.length &&
      familyInfo.email.length &&
      familyInfo.phone.length
    ) {
      return true
    }
    return false
  }

  const isStepTwoValid = (): boolean => {
    const eachSessionValidity: boolean[] = []
    sessionsPerCampers.forEach((sessionPerCamper: SessionsPerCampers) => {
      if (sessionPerCamper.grade.length && sessionPerCamper.weeks.length) {
        eachSessionValidity.push(true)
      } else {
        eachSessionValidity.push(false)
      }
    })

    if (eachSessionValidity.every((value: boolean) => value === true)) {
      return true
    }
    return false
  }

  const isStepThreeValid = (): boolean => {
    if (
      familyInfo.firstName.length &&
      familyInfo.lastName.length &&
      familyInfo.email.length &&
      familyInfo.occupation.length &&
      familyInfo.phone.length
    ) {
      return true
    }
    return false
  }

  const isStepValid = () => {
    if (step === 1 && isStepOneValid()) {
      return true
    } else if (step === 2 && isStepTwoValid()) {
      return true
    } else if (step === 3 && isStepThreeValid()) {
      return true
    } else if (step === 4) {
      return true
    }

    return false
  }

  const nextClickHandler = () => {
    if (step === 1 && isStepOneValid()) {
      dispatch(stepSlice.actions.setStepNumber(step + 1))
    } else if (step === 2 && isStepTwoValid()) {
      dispatch(stepSlice.actions.setStepNumber(step + 1))
    } else if (step === 3 && isStepThreeValid()) {
      const camperAndSessions: EachCamperSessions[] = []
      sessionsPerCampers.forEach(sessionsPerCamper => {
        camperAndSessions.push({
          allSessions: sessionsPerCamper.weeks,
          grade: sessionsPerCamper.grade
        })
      })
      const data = {
        firstName: familyInfo.firstName,
        lastName: familyInfo.lastName,
        email: familyInfo.email,
        phoneNumber: familyInfo.phone,
        numberOfCampers: campers.length,
        camperAndSessions,
        subTotal: familyInfo.familySessionsCost,
        discounts: familyInfo.totalFamilyDiscount,
        grandTotal: familyInfo.grandTotalFamilyCost
      }
      dispatch(appSlice.actions.setLoading(true))
      axios({
        method: 'POST',
        url: config.libertyLakeWorkflowURL,
        headers: { 'Cache-Control': 'no-cache' },
        data
      })
        .then(res => console.log(res))
        .catch(err => console.log(err))

      dispatch(appSlice.actions.setLoading(false))
      dispatch(stepSlice.actions.setStepNumber(step + 1))
    }
  }

  return <div className='footerContainer'>
    <div className="buttonContainer">
      {step > 1 && (
        <button
          className="back"
          onClick={() => dispatch(stepSlice.actions.setStepNumber(step - 1))}
        >
          Previous
        </button>
      )}
      <button
        className={`next ${step >= 4 && 'hide'}`}
        disabled={!isStepValid() || step === 4}
        onClick={() => nextClickHandler()}
      >
        Next
      </button>
    </div>
  </div>
}

export default Footer
