/* eslint-disable no-use-before-define */
import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'

import LibertyLake from './views/LibertyLake/LibertyLake'
import { GotGameTemplate } from './views/GotGameTemplate'

const App = () => {
  return (
    <Switch>
      <Route path='/liberty-lake' component={LibertyLake} />
      <Route path='/' component={GotGameTemplate}
      />
      <Redirect to='/' />
    </Switch>
  )
}

export default App
