/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react'

const Step5 = ({
  currentStep,
  setCurrentStep,
  programs,
  setSidebarVisible
}: any) => {
  const [, setErrMsg] = useState('')

  useEffect(() => {
    setErrMsg('')
  }, [])

  return (
    <>
      <div className='container' id='step5'>
        <div className='row' style={{ display: 'block' }}>
          <div className='col-md-12'>
            <br />
            <h3 className='col-md-12' style={{ textAlign: 'center' }}>
              Thank you for your reservation!
            </h3>
            <div
              className='col-md-12'
              style={{ textAlign: 'center', color: 'grey' }}
            >
              An email receipt including the details about your reservation has
              been sent to the email address provided. Please keep it for your
              records.
            </div>
            <hr />
            <div className='container' id='sidebar_confirmation' />
          </div>
        </div>
        <br />
      </div>
    </>
  )
}

export { Step5 }
