import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false
}

const setLoading = (state = initialState, action: PayloadAction<boolean>) => {
  return {
    ...state,
    isLoading: action.payload
  }
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoading
  }
})

export default appSlice
