/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
import React, { useEffect, useContext, useState } from 'react'

import { ReservationContext } from '../../../reducers/ReservationReducer'
import { Desktop } from './Desktop'
import { Mobile } from './Mobile'

const Sidebar = ({
  isComplete,
  applyDiscount,
  accountId,
  companyLogo
}: any) => {
  const [reservation] = useContext<any>(ReservationContext)

  const [promoCode, setPromoCode] = useState('')
  const [promoCodeErrMsg, setPromoCodeErrMsg] = useState('')

  const addPromoCode = async () => {
    if (!promoCode) {
      return setPromoCodeErrMsg('Cannot add blank code.')
    }

    // see if promo code already added
    const promoCodePos = reservation.discounts.findIndex((d: any) => {
      return d.code && d.code.toLowerCase() === promoCode.toLowerCase()
    })
    if (promoCodePos > -1) {
      return setPromoCodeErrMsg('Promo Code already in use.')
    }

    const { discounts } = reservation.selectedProgram
    let appliedDiscount

    for (let i = 0; i < discounts.length; i += 1) {
      const discount = discounts[i]
      if (
        discount.code &&
        promoCode.toLowerCase() === discount.code.toLowerCase() &&
        (discount.type === 'percentage' || discount.type === 'fixed')
      ) {
        appliedDiscount = discount
      }
    }

    if (appliedDiscount) {
      try {
        await applyDiscount(appliedDiscount.id)
        return setPromoCodeErrMsg('Code successfully applied')
      } catch (err) {
        return setPromoCodeErrMsg('Unable to apply code')
      }
    }
    setPromoCodeErrMsg('Unable to apply code')
  }

  useEffect(() => {
    setPromoCodeErrMsg('')
  }, [])

  return (
    <>
      <div
        className='col-md-4 xs-12 side-bar inner-frame'
        id='main-sidebar'
        style={{ borderRight: '1px solid rgba(77, 80, 85, 0.1)' }}
      >
        {window.innerWidth > 767 && (
          <Desktop
            accountId={accountId}
            isComplete={isComplete}
            addPromoCode={addPromoCode}
            reservation={reservation}
            promoCode={promoCode}
            setPromoCode={setPromoCode}
            promoCodeErrMsg={promoCodeErrMsg}
            companyLogo={companyLogo}
          />
        )}
        {window.innerWidth <= 767 && (
          <Mobile
            accountId={accountId}
            isComplete={isComplete}
            addPromoCode={addPromoCode}
            reservation={reservation}
            promoCode={promoCode}
            setPromoCode={setPromoCode}
            promoCodeErrMsg={promoCodeErrMsg}
            companyLogo={companyLogo}
          />
        )}
      </div>
    </>
  )
}

export { Sidebar }
