/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUsers,
  faClock,
  faChild,
  faShoppingCart,
  faCheck
} from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

const Progressbar = ({
  currentStep,
  setCurrentStep,
  setSidebarVisible,
  isComplete,
  resetBar,
  setResetBar
}: any) => {
  const [maxReachedStep, setMaxReachedStep] = useState(currentStep || 1)

  useEffect(() => {
    if (currentStep > maxReachedStep) {
      setMaxReachedStep(currentStep)
    }
  }, [currentStep])

  useEffect(() => {
    // If resetBar is set to true, reset the progress bar and set the resetBar back to false
    if (resetBar) {
      setMaxReachedStep(1)
      setResetBar(false)
    }
  }, [resetBar])

  return (
    <>
      <div className='container'>
        <div className='row'>
          <ul id='progressbar'>
            <li
              className={maxReachedStep >= 1 ? 'active' : 'disabled'}
              onClick={() => {
                if (!isComplete) {
                  setSidebarVisible(false)
                  setCurrentStep(1)
                }
              }}
            >
              <FontAwesomeIcon icon={faUsers as IconProp} size='xs' />
              <strong>1. Program</strong>
            </li>
            <li
              className={maxReachedStep >= 2 ? 'active' : 'disabled'}
              onClick={() => {
                if (!isComplete && maxReachedStep >= 2) {
                  setCurrentStep(2)
                }
              }}
            >
              <FontAwesomeIcon icon={faClock as IconProp} size='xs' />
              <strong>2. Time And Options</strong>
            </li>
            <li
              className={maxReachedStep >= 3 ? 'active' : 'disabled'}
              onClick={() => {
                if (!isComplete && maxReachedStep >= 3) {
                  setSidebarVisible(false)
                  setCurrentStep(3)
                }
              }}
            >
              <FontAwesomeIcon icon={faChild as IconProp} size='xs' />
              <strong>3. Family Info</strong>
            </li>
            <li
              className={maxReachedStep >= 4 ? 'active' : 'disabled'}
              onClick={() => {
                if (!isComplete && maxReachedStep >= 4) {
                  setSidebarVisible(true)
                  setCurrentStep(4)
                }
              }}
            >
              <FontAwesomeIcon icon={faShoppingCart as IconProp} size='xs' />
              <strong>4. Checkout</strong>
            </li>
            <li
              className={maxReachedStep >= 5 ? 'active' : 'disabled'}
              onClick={() => {
                if (!isComplete && maxReachedStep >= 5) {
                  setSidebarVisible(true)
                  setCurrentStep(5)
                }
              }}
            >
              <FontAwesomeIcon icon={faCheck as IconProp} size='xs' />
              <strong>5. Confirm</strong>
            </li>
          </ul>
          <div className='progress'>
            <div
              className='progress-bar progress-bar-striped progress-bar-animated'
              role='progressbar'
              aria-valuemin={0}
              aria-valuemax={100}
              style={{ width: `${(currentStep * 20).toFixed()}%` }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export { Progressbar }
