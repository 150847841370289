import { FamilyInfo, SessionsPerCampers } from '../../types/libertyLake'

export const checkIfMultiWeekDiscount = (sessionsPerCampers: SessionsPerCampers[]): boolean => {
  let sum = 0
  sessionsPerCampers.forEach(sessionPerCamper => {
    sum += sessionPerCamper.multiWeekDiscount
  })
  return sum > 0
}

export const checkIfPreSchoolDiscount = (sessionsPerCampers: SessionsPerCampers[]): boolean => {
  let preSchoolDiscount = false
  sessionsPerCampers.forEach(sessionPerCamper => {
    if (sessionPerCamper.grade === 'Pre thru K') {
      preSchoolDiscount = true
    }
  })
  return preSchoolDiscount
}

export const checkIfSiblingDiscount = (sessionsPerCampers: SessionsPerCampers[]) => {
  let sum = 0
  sessionsPerCampers.forEach(sessionPerCamper => {
    sum += sessionPerCamper.siblingDiscountPercentage
  })
  return sum > 0
}

export const checkIfTransportationDiscount = (familyInfo: FamilyInfo): boolean => {
  if (
    (familyInfo.amTransportationDiscount > 0 && familyInfo.amTransportationChoice.includes('$')) ||
    (familyInfo.pmTransportationDiscount > 0 && familyInfo.pmTransportationChoice.includes('$'))
  ) {
    return true
  } else {
    return false
  }
}

export const checkIfOccupationalDiscount = (familyInfo: FamilyInfo) => {
  if (familyInfo.occupation !== 'None') {
    return true
  } else {
    return false
  }
}

export const checkIfEarlyPayDiscount = (familyInfo: FamilyInfo) => {
  if (familyInfo.earlyPayDiscount > 0 && familyInfo.isEarlyPaying === true) {
    return true
  } else {
    return false
  }
}

export const formatNumberWithCommas = (number: number) => {
  if (typeof number !== 'number') {
    number = parseFloat(number)
  }

  if (isNaN(number)) {
    return 'Invalid input'
  }

  return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}
